import axios from "axios";
import { v4 as uuidv4 } from "uuid";
const API_URL = process.env.REACT_APP_API_URL;
const BASE_URL_API = process.env.REACT_APP_BASE_API_URL;
const CHATSDK_URL_API = process.env.REACT_APP_CHATSDK_API;
const CHATSDK_UPLOAD_DOMAIN = process.env.REACT_APP_CHAT_SDK_UPLOAD_DOMAIN;
const WORKER_DOMAIN = process.env.REACT_APP_WORKER_DOMAIN;

export async function getMyGirlsListAPI() {
  return await axios.get(`${API_URL}/operator/me`);
}

export async function getChatSDKInfoById(id: any) {
  return axios.get(`${BASE_URL_API}/user/token-sdk-chat/${id}`);
}

export async function getTotalUnreadMessage(chatSDKAccessToken: string) {
  const config = {
    headers: { Authorization: `Bearer ${chatSDKAccessToken}` },
  };
  if (!chatSDKAccessToken) return;
  return axios.get(`${CHATSDK_URL_API}/dm/me/total-unread-message`, config);
}

export async function chatSDK_getPrivateChatId(
  receiverId: string,
  chatSDKAccessToken: string
) {
  const config = {
    headers: { Authorization: `Bearer ${chatSDKAccessToken}` },
  };

  return await axios.get(
    `${CHATSDK_URL_API}/dm/participant/${receiverId}/boxchat`,
    config
  );
}

export async function chatSDK_sendMessageAPI(
  privateChatId: string,
  receiverId: string,
  chatSDKAccessToken: string,
  content: string
) {
  const config = {
    headers: { Authorization: `Bearer ${chatSDKAccessToken}` },
  };

  const bodyParameters = {
    privateChatId,
    receiverId,
    content,
  };
  return await axios.post(`${CHATSDK_URL_API}/dm/send`, bodyParameters, config);
}

export async function chatSDK_getListAccounts(
  listExternalId: any,
  chatSDKAccessToken: any
) {
  if (!chatSDKAccessToken) return;

  return await axios.get(`${CHATSDK_URL_API}/dm/batch-total-unread`, {
    headers: { Authorization: `Bearer ${chatSDKAccessToken}` },
    params: {
      ids: listExternalId,
    },
  });
}

export async function chatSDK_getListFriends(
  chatSDKAccessToken: string,
  page: number
) {
  const config = {
    headers: { Authorization: `Bearer ${chatSDKAccessToken}` },
  };

  if (!chatSDKAccessToken) return;
  return await axios.get(
    `${CHATSDK_URL_API}/user/me/recent-chats?OnlyPrivateChat=1&page=${page}&pageSize=10`,
    config
  );
}

export async function getListMessages(
  receiverId: string,
  chatSDKAccessToken: string,
  lastToken?: string
) {
  const config = {
    headers: { Authorization: `Bearer ${chatSDKAccessToken}` },
  };

  return await axios.get(
    `${CHATSDK_URL_API}/dm/participant/${receiverId}/messages?pageSize=10&lastToken=${lastToken}`,
    config
  );
}

async function uploadImageToRightnowCloudFlare(
  listImages: any[],
  currentHunterRightnowId: string
) {
  const listObjFileName = listImages?.map((i: any) => {
    return {
      filename: i?.name,
      isPublic: false, //dita và duke confirm chat chỉ dùng private bucket
      isFree: !(i?.price > 0),
    };
  });
  const listResignedUR_Reponse = await axios.post(
    `${BASE_URL_API}/operator-upload-file/take-urls/${currentHunterRightnowId}`,
    { list: listObjFileName }
  );

  if (listResignedUR_Reponse?.status == 201) {
    const listAPI = listResignedUR_Reponse?.data?.map(
      async (i: any, index: number) => {
        const file_buffer = await listImages[index].originFileObj.arrayBuffer();

        return axios.put(i?.url, file_buffer, {
          headers: {
            Authorization: "forceRemove",
            lang: "kr",
            "Content-Type": listImages[index].type,
          },
        });
      }
    );

    await Promise.all(listAPI);
  }

  return listResignedUR_Reponse;
}

export async function uploadMulipleImages(
  listImages: any[],
  chatSDKAccessToken: string,
  chatBoxId: string,
  currentHunterRightnowId: string
) {
  //upload image to righnow cloundFlare
  const listResignedUR_Reponse = await uploadImageToRightnowCloudFlare(
    listImages,
    currentHunterRightnowId
  );

  console.log("list images response: ", listResignedUR_Reponse);

  //upload image to chat SDK

  const listImageInfoForChatSDKFortmat = listResignedUR_Reponse?.data?.map(
    (i: any, index: any) => {
      return {
        fileName: i?.filename,
        path: i?.publicUrl,
        contentType: listImages[index].type,
        meta: `{"lock":true,"price":${
          Number(listImages[index]?.price) || 0
        },"imageId":"${uuidv4()}","heightOfSingleImage":0,"mineType":"${
          listImages[index].type
        }","imageList":"{}","albumId":"","originalName":"${
          i?.filename
        }","createdFileToken":"${i?.fileToken}"}`,
      };
    }
  );

  const formData = new FormData();

  listImageInfoForChatSDKFortmat?.forEach((i: any) =>
    formData.append("files", JSON.stringify(i))
  );

  const resChatSDKUpload: any = await chatSDK_UploadImages(
    formData,
    chatSDKAccessToken,
    chatBoxId
  );

  return resChatSDKUpload?.data || [];
}

export async function chatSDK_UploadImages(
  body: any,
  chatSDKAccessToken: string,
  chatBoxId: string
) {
  if (!chatSDKAccessToken) return;

  const config = {
    headers: { Authorization: `Bearer ${chatSDKAccessToken}` },
  };

  return await axios.post(
    `${CHATSDK_UPLOAD_DOMAIN}/private-chat/${chatBoxId}`,
    body,
    config
  );
}

export async function saveImageChatToRightnowAlbum(
  currentHunterRightnowId: string,
  listImage: any
) {
  const listImageFormat = [...listImage]?.map((i: any) => {
    const metaData = JSON.parse(i?.meta);
    return {
      type: "image", // required
      url: i.path?.replace(WORKER_DOMAIN, ""), // required
      credit: metaData?.price, // optional / required for chat
      externalId: metaData?.imageId, // image id
      isFree: false, // true || false
    };
  });

  const body = {
    type: "chat", // required: chat | profile
    credit: 0, // required
    isHidden: false,
    files: listImageFormat,
  };

  return axios.post(
    `${BASE_URL_API}/operator-album/${currentHunterRightnowId}`,
    body
  );
}

//album

export async function getMyAlbum(
  page = 1,
  limit = 20,
  currentHunterRightnowId: string
) {
  return axios.get(
    `${BASE_URL_API}/operator-album/get_by_userId/${currentHunterRightnowId}`,
    {
      headers: {
        Authorization: "forceRemove",
      },
      params: {
        page,
        limit,
      },
    }
  );
}

export async function getPublicAlbum(
  page = 1,
  limit = 20,
  currentHunterRightnowId: string
) {
  return axios.get(
    `${BASE_URL_API}/operator-album/except_userId/${currentHunterRightnowId}`,
    {
      headers: {
        Authorization: "forceRemove",
      },
      params: {
        page,
        limit,
      },
    }
  );
}

export async function chatSDK_uploadAlbum(
  albums: any[],
  chatSDKAccessToken: string,
  chatBoxId: string
) {
  const chatSDKFormat = albums?.map((i: any, index: any) => {
    let stringFiles = JSON.stringify(JSON.stringify(i?.files));
    //stringFiles = stringFiles?.replaceAll("fileToken", "createdFileToken");

    console.log(stringFiles);
    return {
      fileName: i?.title,
      path: "",
      contentType: "album/album",
      meta: `{"lock":true,"price":${Number(
        i?.credit
      )},"imageId":"","heightOfSingleImage":0,"mineType":"album","imageList":${stringFiles},"albumId":"${
        i?.id
      }","originalName":"${i?.title}"}`,
    };
  });

  const formData = new FormData();

  chatSDKFormat?.forEach((i: any) =>
    formData.append("files", JSON.stringify(i))
  );

  const resChatSDKUpload: any = await chatSDK_UploadImages(
    formData,
    chatSDKAccessToken,
    chatBoxId
  );

  return resChatSDKUpload?.data || [];
}

//album logic up hinh moi khac voi up anh
async function uploadImageOfAlbumToRightnowCloudFlare(
  listImages: any[],
  currentHunterRightnowId: string,
  albumPrice: number
) {
  const listObjFileName = listImages?.map((i: any) => {
    return {
      filename: i?.name,
      isPublic: false, // duke, dita confirm album luon vao private bucket
      isFree: !(albumPrice > 0), //cho khac do worker nay
    };
  });
  const listResignedUR_Reponse = await axios.post(
    `${BASE_URL_API}/operator-upload-file/take-urls/${currentHunterRightnowId}`,
    { list: listObjFileName }
  );

  if (listResignedUR_Reponse?.status == 201) {
    const listAPI = listResignedUR_Reponse?.data?.map(
      async (i: any, index: number) => {
        const file_buffer = await listImages[index].originFileObj.arrayBuffer();

        return axios.put(i?.url, file_buffer, {
          headers: {
            Authorization: "forceRemove",
            lang: "kr",
            "Content-Type": listImages[index].type,
          },
        });
      }
    );

    await Promise.all(listAPI);
  }

  return listResignedUR_Reponse;
}

export async function createAlbum(
  nane: string | undefined,
  price: number,
  isPublic: boolean,
  listImages: any[],
  currentHunterRightnowId: string
) {
  //upload image to righnow cloundFlare
  const listResignedUR_Reponse = await uploadImageOfAlbumToRightnowCloudFlare(
    listImages,
    currentHunterRightnowId,
    price
  );

  if (listResignedUR_Reponse?.status == 201) {
    const listImgs = listResignedUR_Reponse?.data;

    const listImageFormat = [...listImgs]?.map((i: any, index: number) => {
      if (price == 0)
        return {
          type: "image", // required
          url: i?.filename, // required
          externalId: uuidv4(), // image id
          isFree: true, // true || false //album bằng 0 thì mọi ảnh bên trong free
          fileToken: i?.fileToken,
        };
      else
        return {
          type: "image", // required
          url: i?.filename, // required
          externalId: uuidv4(), // image id
          isFree: index == 0, // true || false // album có giá chỉ ảnh đầu tiên free
          fileToken: i?.fileToken,
        };
    });

    console.log(listResignedUR_Reponse);
    console.log("create album", listImageFormat);

    const body = {
      type: "profile", // required: chat | profile
      credit: price, // required
      isHidden: !isPublic,
      files: listImageFormat,
      title: nane,
    };

    return axios.post(
      `${BASE_URL_API}/operator-album/${currentHunterRightnowId}`,
      body
    );
  }
}

export async function getRightnowInfoById(userId: string) {
  return await axios.get(`${API_URL}/user/active-user-detail/${userId}`);
}

export async function translateMessage(text: string, toLang: string) {
  return axios.post(`${API_URL}/operator/translate`, { text, toLang });
}

export async function getImageFromWorker(imageURL: string) {
  return await axios.get(imageURL, {
    responseType: "blob",
  });
}
