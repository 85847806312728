import { EVENT_NAME } from "app/constants/event";
import { useCallback } from "react";

export interface DataMessageSend {
  groupId?: string;
  groupName?: string;
  receiverId?: string;
  receiverName?: string;
  type?: string;
  content: string;
  clientId: string;
  attachments?: any[];
  // stickers?: Sticker[]
}

export interface IPrivateChat {
  id: string;
  aliasName: string;
  participants: any[];
}

export async function realtimeChat(socket: any, newMessage: any) {
  // Send a new message
  if (socket) {
    console.log("send mess", newMessage);
    return socket.emit(EVENT_NAME.SEND_MESSAGE, newMessage);
  }
  return null;
}
