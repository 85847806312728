import {
  DEFAULT_IMAGE_URL,
  KTIcon,
  toAbsoluteUrlENV,
  useDebounce,
} from "_metronic/helpers";
import { useEffect, useState } from "react";
import axios from "axios";
import { UserChatType } from "app/contexts/chatSDKContext/type";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link } from "react-router-dom";
import { convertToHCM } from "app/utils";
import { Select, Skeleton } from "antd";
import { ALLCOUNTRIES } from "app/constants";
import male_icon from "_metronic/assets/svgs/male-icon.png";
import female_icon from "_metronic/assets/svgs/female-icon.png";
import { setDefaultAvatarForTestAccount } from "app/utils/operatorUtils";

const API_URL = process.env.REACT_APP_API_URL;

export default function MaleSearch({
  currentHunter,
  currentPrey,
  setCurrentPrey,
  setFriendListCount,
  setShowProfileById,
}: any) {
  const [listBoys, setListBoys] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [keyword, setKeyword] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [filterIsMaleSubsriber, setFilterIsMaleSubsriber] = useState("");

  useEffect(() => {
    setPage(1);
  }, [keyword, filterIsMaleSubsriber]);

  useEffect(() => {
    getListFemale();
  }, [page, keyword, filterIsMaleSubsriber]);

  async function getListFemale() {
    const res = await axios.get(`${API_URL}/user/active-users`, {
      params: {
        page,
        limit: 20,
        gender: currentHunter?.gender == "FEMALE" ? "MALE" : "FEMALE",
        keyword: search,
        typeUser: ["REAL", "TEST", "GUEST"],
        sort_by: "createdAt",
        order_by: "DESC",
        isMaleSubscriber: filterIsMaleSubsriber,
      }, //testing nho doi lai khi commit
    });
    if (res?.status == 200) {
      const formatedArr = res?.data?.data?.map((i: any) => {
        const chatUser: UserChatType = {
          id: i?.id,
          externalId: i?.externalId,
          avatar:
            (i?.typeUser == "TEST"
              ? setDefaultAvatarForTestAccount(i?.gender)
              : i?.pictures?.[0]) || DEFAULT_IMAGE_URL,
          nickName: i?.nickName,
          countUnread: 0,
          createdAt: i?.createdAt,

          age: i?.age,
          countryCode: i?.countryCode,
          gender: i?.gender,
          isMaleSubscribe: i?.wallet?.rightnowPlusUntil,
          rawRightnowInfo: i,
        };
        return chatUser;
      });

      setFriendListCount(res?.data?.count);

      if (page == 1) setListBoys(formatedArr);
      else
        setListBoys((preState: any) => {
          return [...preState, ...formatedArr];
        });
    }

    setIsLoading(false);
  }

  const debouncedSearchTerm = useDebounce(search, 500);

  useEffect(() => {
    setPage(1);
    setKeyword(search);
  }, [debouncedSearchTerm]);

  return (
    <div className="card card-flush">
      <div
        style={{ padding: "0 2.25rem" }}
        className="custom_filter_subscriber_select d-flex justify-content-end"
      >
        <Select
          value={filterIsMaleSubsriber}
          style={{ minWidth: 140 }}
          onChange={async (value) => {
            setFilterIsMaleSubsriber(value);
          }}
          options={[
            { value: "", label: "All" },
            { value: "subscriber", label: "Subscribed" },
            { value: "non-subscriber", label: "Non-Subscribed" },
          ]}
        />
      </div>
      <div className="card-header pt-7" id="kt_chat_contacts_header">
        <div className="w-100 position-relative">
          <KTIcon
            iconName="magnifier"
            className="fs-2 text-lg-1 text-gray-500 position-absolute top-50 ms-5 translate-middle-y"
          />

          <input
            type="text"
            className="form-control form-control-solid px-15"
            name="search"
            value={search}
            placeholder="Search by username or email..."
            onChange={(e) => setSearch(e?.target?.value)}
          />

          {search?.length > 0 && (
            <div onClick={() => setSearch("")}>
              <KTIcon
                iconName="cross"
                className="fs-2 text-lg-1 text-gray-500 position-absolute top-50 end-0 me-5 translate-middle-y cursor-pointer"
              />
            </div>
          )}
        </div>
      </div>

      <div className="card-body pt-5" id="kt_chat_contacts_body">
        <InfiniteScroll
          dataLength={listBoys?.length}
          hasMore={true}
          next={() => {
            console.log("next");
            setPage(page + 1);
          }}
          loader=""
          height={"60vh"}
        >
          {isLoading && (
            <>
              <Skeleton loading={true} active avatar />
              <Skeleton loading={true} active avatar />
              <Skeleton loading={true} active avatar />
            </>
          )}
          {!isLoading &&
            listBoys?.map((i: any) => {
              return (
                <>
                  <div
                    className="d-flex flex-stack py-4"
                    onClick={() => {
                      setCurrentPrey(i);
                    }}
                    style={
                      i?.id == currentPrey?.id
                        ? {
                            background: "#f3f3f3",
                          }
                        : {}
                    }
                  >
                    <div className="d-flex">
                      <div
                        onClick={() => setShowProfileById(i?.id)}
                        className="cursor-pointer symbol symbol-45px symbol-circle"
                      >
                        {i?.avatar ? (
                          <img alt="Pic" src={toAbsoluteUrlENV(i?.avatar)} />
                        ) : (
                          <span className="symbol-label bg-light-danger text-danger fs-6 fw-bolder">
                            {i?.nickName?.substring(0, 1)}
                          </span>
                        )}
                      </div>

                      <div className="ms-5">
                        <div className="fs-5 fw-bolder text-gray-900 text-hover-primary cursor-pointer">
                          <span onClick={() => setShowProfileById(i?.id)}>
                            {i?.rawRightnowInfo?.deletedAt ? (
                              <del>{i?.nickName}</del>
                            ) : (
                              i?.nickName
                            )}
                          </span>
                        </div>

                        <div
                          className="text-gray-400"
                          style={{
                            fontSize: 11,
                            display: "flex",
                            gap: 5,
                            alignItems: "center",
                          }}
                        >
                          <div style={{ fontSize: 13 }}>{i?.age}</div>
                          <div style={{ fontSize: 15 }}>
                            {
                              ALLCOUNTRIES.find((c: any) => {
                                return c?.countryCodes
                                  ?.toString()
                                  ?.toLowerCase()
                                  .includes(i?.countryCode);
                              })?.flag
                            }
                          </div>

                          <img
                            src={i?.gender == "MALE" ? male_icon : female_icon}
                            style={{ width: 12, height: 12 }}
                          />

                          {i?.rawRightnowInfo?.userJob?.[0]?.value?.en}

                          {i?.gender == "MALE" && i?.isMaleSubscribe && (
                            <span className="badge bg-success text-light">
                              Subscribed
                            </span>
                          )}
                        </div>
                        <p className="text-muted fs-8">
                          {convertToHCM(i.createdAt)}
                        </p>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
        </InfiniteScroll>
      </div>
    </div>
  );
}
