/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { KTIcon } from "_metronic/helpers";
import { Modal } from "react-bootstrap";
import { createPortal } from "react-dom";
import { useIntl } from "react-intl";
import { Image } from "antd";
import { getBase64 } from "app/utils";
import { RcFile } from "antd/es/upload";
import ImageUploadPreview from "../uploadImageReview";

const modalsRoot = document.getElementById("root-modals") || document.body;

const SetPriceImageModal = ({
  handleClose,
  tempImgs,
  setTempImgs,
  handleSendMess,
}: any) => {
  const intl = useIntl();

  const handleOnCloseModal = () => {
    handleClose();
  };

  return createPortal(
    <Modal
      id="kt_modal_create_app"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-900px admin-info-modal"
      show={true}
      onHide={handleOnCloseModal}
      backdrop={true}
      onBackdropClick={handleOnCloseModal}
    >
      <div className="modal-header">
        <h2>{intl.formatMessage({ id: "SET-PRICE" })}</h2>
        {/* begin::Close */}
        <div
          className="btn btn-sm btn-icon btn-active-color-primary"
          onClick={handleOnCloseModal}
        >
          <KTIcon className="fs-1" iconName="cross" />
        </div>
        {/* end::Close */}
      </div>

      <div
        className="modal-body py-lg-10 px-lg-10"
        // style={{ minWidth: "750px" }}
      >
        {/*begin::Stepper */}
        <div
          className="stepper stepper-pills stepper-column d-flex flex-column flex-xl-row "
          id="kt_modal_create_app_stepper"
        >
          {/*begin::Content */}
          <div style={{ minWidth: "100%" }}>
            {/*begin::Form */}
            <form
              noValidate
              id="kt_modal_create_app_form"
              autoComplete="off"
              onSubmit={() => {
                handleSendMess();
                handleClose();
              }}
              className="customizedForm w-600px"
            >
              <div className="current" data-kt-stepper-element="content">
                <div className="w-100 row">
                  {tempImgs?.map((i: any, index: number) => {
                    return (
                      <div
                        className={`${
                          tempImgs.length > 2 ? "col-6" : "col"
                        } d-flex align-items-center gap-5 mb-10`}
                      >
                        <div style={{ width: 70 }}>
                          <ImageUploadPreview imageFile={i?.originFileObj} />
                        </div>
                        <div className="fv-row col">
                          <input
                            className="form-control form-control-lg form-control-solid"
                            placeholder={intl.formatMessage({
                              id: "TYPE-IN-HERE",
                            })}
                            type="number"
                            value={tempImgs[index]?.price?.toString() || 0}
                            onChange={(e: any) => {
                              const cloneItem = {
                                ...tempImgs[index],
                                price: Math.abs(e?.target?.value),
                              };
                              const newlist = [...tempImgs];
                              newlist[index] = cloneItem;
                              setTempImgs(newlist);
                            }}
                          />
                        </div>
                      </div>
                    );
                  })}
                  {/* VI */}
                </div>
              </div>
              <div className="d-flex flex-stack justify-content-end">
                <button className="btn btn-lg btn-primary" type="submit">
                  {intl.formatMessage({ id: "SAVE" })}
                </button>
              </div>
            </form>
            {/*end::Form */}
          </div>
          {/*end::Content */}
        </div>
        {/* end::Stepper */}
      </div>
    </Modal>,
    modalsRoot
  );
};

export { SetPriceImageModal };
