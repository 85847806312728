/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from "react";
import PreyList from "./components/PreyList";
import HunterList from "./components/HunterList";
import { ChatBox } from "./components/female/ChatBox";
import { MaleChatBox } from "./components/male/MaleChatBox";
import MaleSearch from "./components/MaleSearch";
import { KTIcon, toAbsoluteUrlENV } from "_metronic/helpers";
import UserProfileModal from "./UserProfileModal";
import ChatBoxHeader from "./components/ChatBoxHeader";

const Private: FC = () => {
  const [currentHunter, setCurrentHunter] = useState<any>();
  const [currentPrey, setCurrentPrey] = useState<any>(null);
  const [menuOption, setMenuOption] = useState("friends");
  const [friendListCount, setFriendListCount] = useState(0);
  const [showProfileById, setShowProfileById] = useState(false);

  useEffect(() => setMenuOption("friends"), [currentHunter]);

  console.log(currentPrey);

  return (
    <>
      {showProfileById && (
        <UserProfileModal
          userId={showProfileById}
          onClose={() => setShowProfileById(false)}
        />
      )}
      <div className="d-flex flex-column flex-lg-row">
        <div className="flex-column flex-lg-row-auto w-100 w-lg-300px w-xl-400px mb-10 mb-lg-0">
          <HunterList
            currentHunter={currentHunter}
            setCurrentHunter={setCurrentHunter}
            setShowProfileById={setShowProfileById}
          />
        </div>
        <div className="flex-column flex-lg-row-auto w-100 w-lg-300px w-xl-400px mb-10 mb-lg-0 ms-lg-7 ms-xl-10">
          <div className="card card-flush" style={{ height: "100%" }}>
            <div className="card-header">
              <div className="card-title">
                <div className="symbol-group symbol-hover"></div>
                <div className="d-flex justify-content-center flex-column me-3">
                  <a
                    href="#"
                    className="fs-4 fw-bolder text-gray-900 text-hover-primary lh-1"
                  >
                    Friend List{" "}
                    {friendListCount > 0 ? ` - (${friendListCount})` : null}
                  </a>
                </div>
              </div>

              <div className="d-flex justify-content-end gap-3 align-items-center">
                {menuOption == "friends" ? (
                  <div
                    onClick={() => setMenuOption("friends")}
                    style={{
                      background: "#4DB4FF",
                      borderRadius: 8,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: 4,
                      padding: "4px 8px",
                      cursor: "pointer",
                    }}
                  >
                    <KTIcon
                      iconName="message-text-2"
                      className="fs-2 text-lg-1 text-white"
                    />
                    <div
                      style={{ fontWeight: 500, fontSize: 10, color: "white" }}
                    >
                      Chat
                    </div>
                  </div>
                ) : (
                  <div
                    onClick={() => setMenuOption("friends")}
                    className="d-flex"
                  >
                    <KTIcon
                      iconName="message-text-2"
                      className="fs-2 text-lg-1 text-gray-500 cursor-pointer"
                    />
                  </div>
                )}

                {menuOption == "find-men" ? (
                  <div
                    onClick={() => setMenuOption("find-men")}
                    style={{
                      background: "#4DB4FF",
                      borderRadius: 8,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: 4,
                      padding: "4px 8px",
                      cursor: "pointer",
                    }}
                  >
                    <KTIcon
                      iconName="profile-user"
                      className="fs-2 text-lg-1 text-white"
                    />
                    <div
                      style={{
                        fontWeight: 500,
                        fontSize: 10,
                        color: "white",
                      }}
                    >
                      Explore
                    </div>
                  </div>
                ) : (
                  <div
                    onClick={() => setMenuOption("find-men")}
                    className="d-flex cursor-ponter"
                  >
                    <KTIcon
                      iconName="profile-user"
                      className="fs-2 text-lg-1 text-gray-500 cursor-pointer"
                    />
                  </div>
                )}
              </div>
            </div>

            {menuOption == "friends" && (
              <PreyList
                currentHunter={currentHunter}
                currentPrey={currentPrey}
                setCurrentPrey={setCurrentPrey}
                setFriendListCount={setFriendListCount}
                setShowProfileById={setShowProfileById}
                key={`${currentHunter?.id}`}
              />
            )}
            {menuOption == "find-men" && (
              <MaleSearch
                key={`${currentHunter?.id}`}
                currentHunter={currentHunter}
                currentPrey={currentPrey}
                setCurrentPrey={setCurrentPrey}
                setFriendListCount={setFriendListCount}
                setShowProfileById={setShowProfileById}
              />
            )}
          </div>
        </div>
        <div className="flex-lg-row-fluid ms-lg-7 ms-xl-10">
          <div
            className="card"
            id="kt_chat_messenger"
            style={{ height: "100%" }}
          >
            <ChatBoxHeader
              currentHunter={currentHunter}
              currentPrey={currentPrey}
              setShowProfileById={setShowProfileById}
            />
            {currentHunter?.gender == "MALE" ? (
              <MaleChatBox
                key={`${currentHunter?.id}-${currentPrey?.id}`}
                currentHunter={currentHunter}
                currentPrey={currentPrey}
                setShowProfileById={setShowProfileById}
              />
            ) : (
              <ChatBox
                key={`${currentHunter?.id}-${currentPrey?.id}`}
                currentHunter={currentHunter}
                currentPrey={currentPrey}
                setShowProfileById={setShowProfileById}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Private;
