import React, { useEffect, useState } from "react";

const ImageUploadPreview = ({ imageFile }: any) => {
  const [imagePreview, setImagePreview] = useState<any>(null);

  useEffect(() => {
    if (imageFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(imageFile);
    }
  }, [imageFile]);

  return (
    <div className="image-upload-preview">
      {imagePreview && (
        <img
          src={imagePreview}
          alt="Preview"
          style={{ maxWidth: "70px", maxHeight: "70px" }}
        />
      )}
    </div>
  );
};

export default ImageUploadPreview;
