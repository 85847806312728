import { FC, createContext, useContext, useEffect, useState } from "react";

type ChatContextProps = {
  message: any;
  saveMessage: (message: any) => void;
  messageError: any;
  saveMessageError: (messageError: any) => void;
  notification: any;
  saveNotification: (notification: any) => void;
};

const initChatContextPropsState = {
  message: undefined,
  saveMessage: () => {},
  messageError: undefined,
  saveMessageError: () => {},
  notification: undefined,
  saveNotification: () => {},
};

const ChatContext = createContext<ChatContextProps>(initChatContextPropsState);

const useChat = () => {
  return useContext(ChatContext);
};

const ChatProvider: FC<any> = ({ children }) => {
  const [message, setMessage] = useState<any>(undefined);
  const [messageError, setMessageError] = useState<any>(undefined);
  const [notification, setNotification] = useState<any>(undefined);

  const saveMessage = (mess: any) => {
    setMessage(mess);
  };

  const saveMessageError = (mess: any) => {
    setMessageError(mess);
  };

  const saveNotification = (noti: any) => {
    setNotification(noti);
  };

  return (
    <ChatContext.Provider
      value={{
        message,
        saveMessage,
        messageError,
        saveMessageError,
        notification,
        saveNotification,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

const ChatInit: FC<any> = ({ children }) => {
  const queryParameters = new URLSearchParams(window.location.search);

  useEffect(() => {}, []);

  return <>{children}</>;
};

export { ChatInit, ChatProvider, useChat };
