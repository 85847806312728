import { MessageModel } from "_metronic/helpers";
import { Upload, notification } from "antd";
import { uploadMulipleImages } from "app/contexts/chatSDKContext/api";
import { nowTransactionChatbox } from "app/contexts/chatSDKContext/male_api";
import { realtimeChat } from "app/contexts/chatSDKContext/socketEmit";
import { useState } from "react";

const REACT_APP_CHAT_SDK_CLIENT_ID = process.env.REACT_APP_CHAT_SDK_CLIENT_ID;

export default function MaleChatFooter({
  currentHunter,
  currentPrey,
  messages,
  setMessages,
  privateChatId,
}: any) {
  const [message, setMessage] = useState<string>("");
  const [tempImgs, setTempImgs] = useState<any>([]);

  const updateMessageLocal = async () => {
    const newMessage: MessageModel = {
      user: 3,
      type: "out",
      text: message,
      time: Date.now(),
      imagesInfo: tempImgs?.map((i: any) => {
        return {
          url: URL.createObjectURL(i.originFileObj),
          price: 0,
          lock: false,
        };
      }),
    };
    const bufferMessages = messages;
    bufferMessages.push(newMessage);
    setMessages(bufferMessages);
    setMessage("");
    setTempImgs([]);
  };

  async function handleSendMess() {
    const chargeNowRes = await nowTransactionChatbox(
      currentHunter?.id,
      currentPrey?.id
    );
    console.log(chargeNowRes);

    if (chargeNowRes?.status != 201) {
      return;
    }

    const errorMessage = chargeNowRes?.data?.error?.message;
    if (errorMessage) {
      notification.error({
        message: errorMessage,
        duration: 3,
      });
      return;
    }
    updateMessageLocal();

    const newMess: any = {
      receiverId: currentPrey?.externalId,
      content: message,
      clientId: REACT_APP_CHAT_SDK_CLIENT_ID,
      boxId: privateChatId,
      //privateChatId: privateChatId,
      participantId: currentPrey?.externalId,
      username: currentPrey?.username,
      avatar: currentPrey?.avatar,
    };

    //async functions
    if (tempImgs?.length > 0) {
      const resURLs = await uploadMulipleImages(
        tempImgs,
        currentHunter?.chatSDKAccessToken,
        privateChatId,
        currentHunter.id
      );
      if (resURLs?.data?.length > 0) {
        newMess.attachments = resURLs?.data;
      }
    }

    realtimeChat(currentHunter?.socket, newMess);
  }

  const onEnterPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      handleSendMess();
    }
  };

  return (
    <div className="card-footer pt-4 p-0" id={"kt_chat_messenger_footer"}>
      <div className="custom-upload-button">
        <Upload
          beforeUpload={() => false}
          listType="picture-card"
          multiple
          fileList={tempImgs}
          maxCount={10}
          onChange={(imgs) => {
            setTempImgs(imgs.fileList);
          }}
        >
          <button id="chooseImages">
            <i className="bi bi-paperclip fs-3"></i>
          </button>
        </Upload>
      </div>
      <div className="d-flex flex-stack justify-content-between">
        <button
          className="btn btn-sm btn-icon btn-active-light-primary me-1 d-flex align-items-center"
          type="button"
          data-bs-toggle="tooltip"
          title="Choose image"
          onClick={() => {
            document.getElementById("chooseImages")?.click();
          }}
        >
          <i className="bi bi-paperclip fs-3"></i>
        </button>

        <textarea
          className="form-control form-control-flush me-10"
          rows={1}
          data-kt-element="input"
          placeholder="You will be charged NOW for every message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={onEnterPress}
        ></textarea>
        <button
          className="btn btn-primary"
          type="button"
          data-kt-element="send"
          onClick={() => {
            handleSendMess();
          }}
          disabled={message?.length == 0 && tempImgs?.length == 0}
        >
          Send
        </button>
      </div>
    </div>
  );
}
