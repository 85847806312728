import { KTIcon } from "_metronic/helpers";
import {
  chatSDK_uploadAlbum,
  createAlbum,
  getMyAlbum,
  getPublicAlbum,
} from "app/contexts/chatSDKContext/api";
import { realtimeChat } from "app/contexts/chatSDKContext/socketEmit";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { createPortal } from "react-dom";
import { useIntl } from "react-intl";
import Gallery from "react-photo-gallery";
import { Segmented, Switch, Upload, notification } from "antd";
import clsx from "clsx";
import { CircularProgress } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { PlusOutlined } from "@ant-design/icons";
import AlbumGridAsyncImage from "./AlbumGridAsyncImage";

const modalsRoot = document.getElementById("root-modals") || document.body;
const REACT_APP_CHAT_SDK_CLIENT_ID = process.env.REACT_APP_CHAT_SDK_CLIENT_ID;

export default function AlbumButton({
  currentHunter,
  currentPrey,
  chatBoxId,
  tempAlbums,
  setTempAlbums,
  updateMessageLocal,
}: any) {
  const [isShowAlbumModal, setIsShowAlbumModal] = useState(false);

  return (
    <>
      <button
        className="btn btn-sm btn-icon btn-active-light-primary me-1 d-flex align-items-center"
        type="button"
        data-bs-toggle="tooltip"
        title="Album"
        onClick={() => setIsShowAlbumModal(true)}
      >
        <i className="bi bi-journal-album fs-3"></i>
      </button>
      {isShowAlbumModal && (
        <AlbumListModal
          handleClose={() => setIsShowAlbumModal(false)}
          currentHunter={currentHunter}
          currentPrey={currentPrey}
          chatBoxId={chatBoxId}
          tempAlbums={tempAlbums}
          setTempAlbums={setTempAlbums}
          updateMessageLocal={updateMessageLocal}
        />
      )}
    </>
  );
}

const AlbumListModal = ({
  handleClose,
  currentHunter,
  currentPrey,
  chatBoxId,
  tempAlbums,
  setTempAlbums,
  updateMessageLocal,
}: any) => {
  const intl = useIntl();

  const [tab, setTab] = useState<any>("Mine");
  const [isShowCreateAlbum, setIsShowCreateAlbum] = useState(false);

  const handleOnCloseModal = () => {
    handleClose();
  };

  return createPortal(
    <Modal
      id="kt_modal_create_app"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-900px admin-info-modal"
      show={true}
      onHide={handleOnCloseModal}
      backdrop={true}
      onBackdropClick={handleOnCloseModal}
    >
      <div className="modal-header">
        <strong className="fs-4">Albums</strong>
        {/* begin::Close */}
        <Segmented
          value={tab}
          onChange={(value) => {
            setTab(value);
            setIsShowCreateAlbum(false);
          }}
          options={["Mine", "Others"]}
        />
        <div
          className="btn btn-sm btn-icon btn-active-color-primary"
          onClick={handleOnCloseModal}
        >
          <KTIcon className="fs-1" iconName="cross" />
        </div>

        {/* end::Close */}
      </div>
      {isShowCreateAlbum ? (
        <CreateAlbum
          goBack={() => setIsShowCreateAlbum(false)}
          currentHunter={currentHunter}
        />
      ) : (
        <>
          {" "}
          <div
            className="cursor-pointer mt-5 px-5"
            style={{ color: "rgba(0,158,247,1)", textAlign: "right" }}
            onClick={() => setIsShowCreateAlbum(true)}
          >
            Create album
          </div>
          <div
            className="modal-body py-lg-10 px-lg-10"
            // style={{ minWidth: "750px" }}
          >
            {/*begin::Stepper */}
            <div
              className="stepper stepper-pills stepper-column d-flex flex-column flex-xl-row"
              id="kt_modal_create_app_stepper"
            >
              {/*begin::Content */}
              <div style={{ minWidth: "100%" }}>
                {/*begin::Form */}
                <PublicAlbumList
                  tab={tab}
                  handleClose={handleClose}
                  currentHunter={currentHunter}
                  currentPrey={currentPrey}
                  chatBoxId={chatBoxId}
                  tempAlbums={tempAlbums}
                  setTempAlbums={setTempAlbums}
                  updateMessageLocal={updateMessageLocal}
                />
                {/*end::Form */}
              </div>
              {/*end::Content */}
            </div>
            {/* end::Stepper */}
          </div>
        </>
      )}
    </Modal>,
    modalsRoot
  );
};

function PublicAlbumList({
  tab,
  handleClose,
  currentHunter,
  currentPrey,
  chatBoxId,
  tempAlbums,
  setTempAlbums,
  updateMessageLocal,
}: any) {
  const intl = useIntl();
  const [page, setPage] = useState(1);
  const [listAlbum, setListAlbum] = useState<any>([]);

  useEffect(() => {
    loadData();
  }, [tab]);

  async function loadData() {
    const res =
      tab == "Mine"
        ? await getMyAlbum(page, 200, currentHunter?.id)
        : await getPublicAlbum(page, 200, currentHunter?.id);

    if (res?.status == 200) setListAlbum(res?.data?.data);
  }

  async function handleSendAlbum() {
    updateMessageLocal();

    //upload album to chatSDK to get id
    //send album to socket
    const newMess: any = {
      receiverId: currentPrey?.externalId,
      content: "",
      clientId: REACT_APP_CHAT_SDK_CLIENT_ID,
      boxId: chatBoxId,
      //privateChatId: chatBoxId,
      participantId: currentPrey?.externalId,
      username: currentPrey?.username,
      avatar: currentPrey?.avatar,
    };

    if (tempAlbums?.length > 0) {
      const resURLs = await chatSDK_uploadAlbum(
        tempAlbums,
        currentHunter?.chatSDKAccessToken,
        chatBoxId
      );
      if (resURLs?.data?.length > 0) newMess.attachments = resURLs?.data;
    }
    realtimeChat(currentHunter?.socket, newMess);
  }

  return (
    <>
      <form
        noValidate
        id="kt_modal_create_app_form"
        autoComplete="off"
        onSubmit={() => {
          handleClose();
        }}
        className="customizedForm w-600px"
      >
        <div className="current" data-kt-stepper-element="content">
          <div className="w-100 row">
            {listAlbum?.length == 0 ? (
              <div style={{ textAlign: "center" }}>Album list is empty</div>
            ) : (
              <div
                className="d-flex flex-wrap gap-5 overflow-scroll"
                style={{ maxHeight: 400 }}
              >
                {listAlbum?.map((i: any) => {
                  const isEnableThisItem = tempAlbums?.includes(i);
                  return (
                    <div
                      style={{
                        width: 100,
                        height: 100,
                        cursor: "pointer",
                        border: isEnableThisItem
                          ? "1px solid rgba(0,158,247,1)"
                          : "",
                        fontSize: 10,
                        fontWeight: 600,
                        textAlign: "center",
                        margin: "10px 0",
                      }}
                      onClick={() => {
                        if (isEnableThisItem)
                          setTempAlbums((preState: any) =>
                            preState.filter((album: any) => album?.id != i?.id)
                          );
                        else setTempAlbums((preState: any) => [...preState, i]);
                      }}
                    >
                      <AlbumGridAsyncImage
                        urlArr={[
                          i?.files[0]?.url,
                          i?.files[1]?.url,
                          i?.files[2]?.url,
                          i?.files[2]?.url,
                        ]}
                      />
                      {/* <Gallery photos={photoArr} /> */}
                      {i?.title} - {i?.credit} NOW
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </form>
      <div className="d-flex flex-stack justify-content-end mt-5">
        <button
          className="btn btn-lg btn-primary"
          type="submit"
          onClick={() => {
            handleSendAlbum();
            handleClose();
          }}
        >
          Send
        </button>
      </div>
    </>
  );
}

function CreateAlbum({ goBack, currentHunter }: any) {
  const intl = useIntl();
  const [tempImgs, setTempImgs] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState();
  const [price, setPrice] = useState(0);
  const [isPublic, setIsPublic] = useState(true);

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: 800,
        }}
        className="pt-10 px-lg-10 px-5"
      >
        <div className="symbol symbol-circle symbol-5px overflow-hidden me-3">
          <div className="symbol-label" style={{ width: 30, height: 30 }}>
            <button className="btn btn-lg text-center" onClick={() => goBack()}>
              <span>
                <i className="fa fa-arrow-left fa-3x"></i>
              </span>
            </button>
          </div>
        </div>
        <div className={clsx("menu-title fw-bold fs-5")}>
          Back to albums list
        </div>
      </div>
      <div className="modal-body py-lg-10 px-lg-10">
        {/*begin::Stepper */}
        <div className="stepper stepper-pills stepper-column d-flex flex-column flex-xl-row ">
          {/*begin::Content */}
          <div className="px-lg-5 w-full">
            {/*begin::Form */}
            <div>
              <Upload
                beforeUpload={() => false}
                listType="picture-card"
                multiple
                fileList={tempImgs}
                maxCount={10}
                onChange={(imgs) => {
                  setTempImgs(imgs.fileList);
                }}
              >
                <button style={{ border: 0, background: "none" }} type="button">
                  <PlusOutlined />
                  <div style={{ marginTop: 8 }}>Upload</div>
                </button>
              </Upload>
            </div>
            <div style={{ fontSize: 11, marginTop: 5 }}>
              Set minimum 2 images and maximum 10 images
            </div>

            <div className="fv-row mt-5">
              <label className="d-flex align-items-center fs-5 fw-semibold mb-2">
                <span>Album Name</span>
              </label>
              <input
                className="form-control form-control-lg form-control-solid"
                value={name}
                onChange={(e: any) => setName(e?.target?.value)}
              />
            </div>
            <div className="fv-row mt-5">
              <label className="d-flex align-items-center fs-5 fw-semibold mb-2">
                <span>Price</span>
              </label>
              <input
                className="form-control form-control-lg form-control-solid"
                type="number"
                value={price.toString()}
                onChange={(e: any) => setPrice(Math.abs(e?.target?.value))}
              />
            </div>
            <div className="fv-row mt-5">
              <label className="d-flex align-items-center fs-5 fw-semibold mb-2">
                <span>Public</span>
              </label>
              <Switch
                checked={isPublic}
                onChange={(value) => setIsPublic(value)}
              />
            </div>
            <div style={{ fontSize: 11, marginTop: 5 }}>
              When set to public, other users can view the album and sell it on
              their behalf. If sold, the original author earns a profit.
            </div>
            <div style={{ fontSize: 11 }}>
              If another member sells your Album,can get 80% of the Now value
              you have set.
            </div>
            {/*begin::Actions */}
            <div className="d-flex flex-stack pt-10">
              <div className="me-2"></div>
              <div>
                <button
                  className="btn btn-lg btn-primary d-flex align-items-center justify-content-center"
                  disabled={loading || tempImgs?.length <= 1}
                  onClick={async () => {
                    setLoading(true);
                    const res = await createAlbum(
                      name,
                      price,
                      isPublic,
                      tempImgs,
                      currentHunter?.id
                    );
                    console.log(res);
                    setLoading(false);
                    if (res?.status == 201) {
                      notification.success({
                        message: "Create album successfully",
                      });
                      goBack();
                    }
                  }}
                >
                  {loading ? (
                    <CircularProgress size={22} style={{ color: "white" }} />
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
            </div>
            {/*end::Actions */}
            {/*end::Form */}
          </div>
          {/*end::Content */}
        </div>
        {/* end::Stepper */}
      </div>
    </>
  );
}
