export const PERMISSIONS = {
  USER: {
    VIEW_LIST: "list-admin",
    VIEW_DETAIL: "view-admin",
  },

  QA: {
    ANSWER: "answer-question",
  },

  JOURNEY: {
    VIEW_LIST: "list-journey",
  },

  SHOP: {
    VIEW_LIST: "list-location",
    CREATE_IMPORT: "create-import-location",
    UPDATE: "update-location",
    DELETE: "delete-location",
  },

  COUPON: {
    VIEW_LIST: "list-coupon",
    CREATE_IMPORT: "create-import-coupon",
    UPDATE: "update-coupon",
    DELETE: "delete-coupon",
  },

  CAR: {
    VIEW_LIST: "list-journey",
  },

  NOTIFICATION: {
    VIEW_LIST: "list-notification",
    CREATE: "create-notification",
  },

  POLICY: {
    VIEW_LIST: "list-policy",
    CREATE: "create-policy",
    UPDATE: "update-policy",
    DELETE: "delete-policy",
  },

  BANNER: {
    VIEW_LIST: "list-banner",
    CREATE: "create-banner",
    UPDATE: "update-banner",
    DELETE: "delete-banner",
  },

  ADMIN: {
    VIEW_LIST: "list-admin",
    CREATE: "create-admin",
    UPDATE: "update-admin",
    DELETE: "delete-admin",
  },

  ROLE: {
    VIEW_LIST: "list-role",
    CREATE: "create-role",
    UPDATE: "update-role",
    DELETE: "delete-role",
  },
};

export const PLATFORM = [
  {
    value: "google",
    label: "PLATFORM-GOOGLE",
  },

  {
    value: "apple",
    label: "PLATFORM-APPLE",
  },

  {
    value: "amazon",
    label: "PLATFORM-AMAZON",
  },

  {
    value: "facebook",
    label: "PLATFORM-FACEBOOK",
  },

  {
    value: "naver",
    label: "PLATFORM-NAVER",
  },

  {
    value: "kakao",
    label: "PLATFORM-KAKAO",
  },

  {
    value: "wishnote",
    label: "PLATFORM-WISHNOTE",
  },
];

export const POST_TYPE = [
  {
    value: "goal",
    label: "GOAL",
  },

  {
    value: "wish",
    label: "WISHBOARD",
  },
];

export const POST_SHOW_STATUS = [
  {
    value: "SHOW",
    label: "SHOW",
  },

  {
    value: "DELETED",
    label: "DELETED",
  },
];

export const NOTIFICATION_STATUS = [
  {
    value: "waiting",
    label: "NOTIFY.STATUS.WAITING",
  },

  {
    value: "completed",
    label: "NOTIFY.STATUS.COMPLETED",
  },
];

export const NOTIFICATION_DEVICE = [
  {
    value: "all",
    label: "ALL",
  },

  {
    value: "ios",
    label: "IOS",
  },
  {
    value: "android",
    label: "ANDROID",
  },
];

export const CS_REPLY = [
  {
    value: "all",
    label: "ALL",
  },

  {
    value: "1",
    label: "YES",
  },
  {
    value: "0",
    label: "NO",
  },
];

export const ACCOUNT_TYPE = [
  { value: "", label: "ALL", color: "rgba(0, 0, 0, 0.25)" },
  { value: "0", label: "NORMAL", color: "rgba(0, 0, 0, 0.25)" },
  { value: "1", label: "MASTER", color: "gold" },
  { value: "2", label: "INFLUENCER", color: "lime" },
];

export const ACCOUNT_TYPE_UPDATE = [
  { value: "0", label: "NORMAL", color: "rgba(0, 0, 0, 0.25)" },
  { value: "1", label: "MASTER", color: "gold" },
  { value: "2", label: "INFLUENCER", color: "lime" },
];

export const NOTIFY_TYPE = [
  { value: "", label: "ALL", color: "rgba(0, 0, 0, 0.25)" },
  { value: "1", label: "MANUAL", color: "rgba(0, 0, 0, 0.25)" },
  { value: "2", label: "AUTO", color: "gold" },
];

export const OS_OPTIONS = [
  {
    value: "all",
    label: "ALL",
  },

  {
    value: "ios",
    label: "IOS",
  },
  {
    value: "android",
    label: "ANDROID",
  },
];

export const RANKING_SETTING_STATUS_OPTIONS = [
  {
    value: "all",
    label: "ALL",
  },

  {
    value: "private",
    label: "PRIVATE",
  },
  {
    value: "public",
    label: "PUBLIC",
  },
];

export const RANKING_SETTING_EDIT_STATUS_OPTIONS = [
  {
    value: "private",
    label: "PRIVATE",
  },
  {
    value: "public",
    label: "PUBLIC",
  },
];
export const AGENCY_SETTING_EDIT_STATUS_OPTIONS = [
  {
    value: "",
    label: "ALL",
  },
  {
    value: "active",
    label: "STATUS.ACTIVE",
  },
  {
    value: "inactive",
    label: "STATUS.INACTIVE",
  },
];

export const EXTRA_TYPE = [
  {
    value: "charge",
    label: "CHARGE",
  },
  {
    value: "revenue",
    label: "REVENUE",
  },

  {
    value: "revenue_chat",
    label: "REVENUE_CHAT",
  },
  {
    value: "mission",
    label: "MISSION",
  },

  {
    value: "commission",
    label: "COMMISSION",
  },

  {
    value: "refund_level",
    label: "REFUND_LEVEL",
  },
  {
    value: "refund",
    label: "REFUND",
  },
  {
    value: "purchase",
    label: "PURCHASE",
  },

  {
    value: "withdrawal",
    label: "WITHDRAWAL",
  },

  {
    value: "purchase_chat",
    label: "PURCHASE_CHAT",
  },

  {
    value: "purchase_explore",
    label: "PURCHASE_EXPLORE",
  },
];

export const GENDER = [
  {
    value: "",
    label: "ALL",
  },
  {
    value: "MALE",
    label: "MALE",
  },
  {
    value: "FEMALE",
    label: "FEMALE",
  },
  {
    value: "OTHER",
    label: "OTHER",
  },
];

export const ROLE = [
  {
    value: "USER",
    label: "USER",
  },
  {
    value: "ADMIN",
    label: "ADMIN",
  },
  {
    value: "AGENCY",
    label: "AGENCY",
  },
  {
    value: "USER_AGENCY",
    label: "USER_AGENCY",
  },
];

export const USERTYPE = [
  {
    value: "",
    label: "ALL",
  },
  {
    value: "FAKE",
    label: "FAKE",
  },
  {
    value: "REAL",
    label: "REAL",
  },
];

export const ALLCOUNTRIES = [
  {
    code: "VN",
    name: "Vietnam",
    flag: "🇻🇳",
    countryCodes: ["+84"],
  },
  {
    code: "KR",
    name: "South Korea",
    flag: "🇰🇷",
    countryCodes: ["+82"],
  },
  {
    code: "JP",
    name: "Japan",
    flag: "🇯🇵",
    countryCodes: ["+81"],
  },
  {
    code: "CN",
    name: "China",
    flag: "🇨🇳",
    countryCodes: ["+86"],
  },
  {
    code: "US",
    name: "United States",
    flag: "🇺🇸",
    countryCodes: ["+1"],
  },
  {
    code: "AF",
    name: "Afghanistan",
    flag: "🇦🇫",
    countryCodes: ["+93"],
  },
  {
    code: "AX",
    name: "Åland Islands",
    flag: "🇦🇽",
    countryCodes: ["+35818"],
  },
  {
    code: "AL",
    name: "Albania",
    flag: "🇦🇱",
    countryCodes: ["+355"],
  },
  {
    code: "DZ",
    name: "Algeria",
    flag: "🇩🇿",
    countryCodes: ["+213"],
  },
  {
    code: "AS",
    name: "American Samoa",
    flag: "🇦🇸",
    countryCodes: ["+1"],
  },
  {
    code: "AD",
    name: "Andorra",
    flag: "🇦🇩",
    countryCodes: ["+376"],
  },
  {
    code: "AO",
    name: "Angola",
    flag: "🇦🇴",
    countryCodes: ["+244"],
  },
  {
    code: "AI",
    name: "Anguilla",
    flag: "🇦🇮",
    countryCodes: ["+1"],
  },
  {
    code: "AG",
    name: "Antigua and Barbuda",
    flag: "🇦🇬",
    countryCodes: ["+1"],
  },
  {
    code: "AR",
    name: "Argentina",
    flag: "🇦🇷",
    countryCodes: ["+54"],
  },
  {
    code: "AM",
    name: "Armenia",
    flag: "🇦🇲",
    countryCodes: ["+374"],
  },
  {
    code: "AW",
    name: "Aruba",
    flag: "🇦🇼",
    countryCodes: ["+297"],
  },
  {
    code: "AU",
    name: "Australia",
    flag: "🇦🇺",
    countryCodes: ["+61"],
  },
  {
    code: "AT",
    name: "Austria",
    flag: "🇦🇹",
    countryCodes: ["+43"],
  },
  {
    code: "AZ",
    name: "Azerbaijan",
    flag: "🇦🇿",
    countryCodes: ["+994"],
  },
  {
    code: "BS",
    name: "Bahamas",
    flag: "🇧🇸",
    countryCodes: ["+1"],
  },
  {
    code: "BH",
    name: "Bahrain",
    flag: "🇧🇭",
    countryCodes: ["+973"],
  },
  {
    code: "BD",
    name: "Bangladesh",
    flag: "🇧🇩",
    countryCodes: ["+880"],
  },
  {
    code: "BB",
    name: "Barbados",
    flag: "🇧🇧",
    countryCodes: ["+1"],
  },
  {
    code: "BY",
    name: "Belarus",
    flag: "🇧🇾",
    countryCodes: ["+375"],
  },
  {
    code: "BE",
    name: "Belgium",
    flag: "🇧🇪",
    countryCodes: ["+32"],
  },
  {
    code: "BZ",
    name: "Belize",
    flag: "🇧🇿",
    countryCodes: ["+501"],
  },
  {
    code: "BJ",
    name: "Benin",
    flag: "🇧🇯",
    countryCodes: ["+229"],
  },
  {
    code: "BM",
    name: "Bermuda",
    flag: "🇧🇲",
    countryCodes: ["+1"],
  },
  {
    code: "BT",
    name: "Bhutan",
    flag: "🇧🇹",
    countryCodes: ["+975"],
  },
  {
    code: "BO",
    name: "Bolivia",
    flag: "🇧🇴",
    countryCodes: ["+591"],
  },
  {
    code: "BA",
    name: "Bosnia and Herzegovina",
    flag: "🇧🇦",
    countryCodes: ["+387"],
  },
  {
    code: "BW",
    name: "Botswana",
    flag: "🇧🇼",
    countryCodes: ["+267"],
  },
  {
    code: "BV",
    name: "Bouvet Island",
    flag: "🇧🇻",
    countryCodes: ["+47"],
  },
  {
    code: "BR",
    name: "Brazil",
    flag: "🇧🇷",
    countryCodes: ["+55"],
  },
  {
    code: "IO",
    name: "British Indian Ocean Territory",
    flag: "🇮🇴",
    countryCodes: ["+246"],
  },
  {
    code: "VG",
    name: "British Virgin Islands",
    flag: "🇻🇬",
    countryCodes: ["+1"],
  },
  {
    code: "BN",
    name: "Brunei",
    flag: "🇧🇳",
    countryCodes: ["+673"],
  },
  {
    code: "BG",
    name: "Bulgaria",
    flag: "🇧🇬",
    countryCodes: ["+359"],
  },
  {
    code: "BF",
    name: "Burkina Faso",
    flag: "🇧🇫",
    countryCodes: ["+226"],
  },
  {
    code: "BI",
    name: "Burundi",
    flag: "🇧🇮",
    countryCodes: ["+257"],
  },
  {
    code: "KH",
    name: "Cambodia",
    flag: "🇰🇭",
    countryCodes: ["+855"],
  },
  {
    code: "CM",
    name: "Cameroon",
    flag: "🇨🇲",
    countryCodes: ["+237"],
  },
  {
    code: "CA",
    name: "Canada",
    flag: "🇨🇦",
    countryCodes: ["+1"],
  },
  {
    code: "CV",
    name: "Cape Verde",
    flag: "🇨🇻",
    countryCodes: ["+238"],
  },
  {
    code: "BQ",
    name: "Caribbean Netherlands",
    flag: "🇧🇶",
    countryCodes: ["+599"],
  },
  {
    code: "KY",
    name: "Cayman Islands",
    flag: "🇰🇾",
    countryCodes: ["+1"],
  },
  {
    code: "CF",
    name: "Central African Republic",
    flag: "🇨🇫",
    countryCodes: ["+236"],
  },
  {
    code: "TD",
    name: "Chad",
    flag: "🇹🇩",
    countryCodes: ["+235"],
  },
  {
    code: "CL",
    name: "Chile",
    flag: "🇨🇱",
    countryCodes: ["+56"],
  },
  {
    code: "CX",
    name: "Christmas Island",
    flag: "🇨🇽",
    countryCodes: ["+61"],
  },
  {
    code: "CC",
    name: "Cocos (Keeling) Islands",
    flag: "🇨🇨",
    countryCodes: ["+61"],
  },
  {
    code: "CO",
    name: "Colombia",
    flag: "🇨🇴",
    countryCodes: ["+57"],
  },
  {
    code: "KM",
    name: "Comoros",
    flag: "🇰🇲",
    countryCodes: ["+269"],
  },
  {
    code: "CK",
    name: "Cook Islands",
    flag: "🇨🇰",
    countryCodes: ["+682"],
  },
  {
    code: "CR",
    name: "Costa Rica",
    flag: "🇨🇷",
    countryCodes: ["+506"],
  },
  {
    code: "HR",
    name: "Croatia",
    flag: "🇭🇷",
    countryCodes: ["+385"],
  },
  {
    code: "CU",
    name: "Cuba",
    flag: "🇨🇺",
    countryCodes: ["+53"],
  },
  {
    code: "CW",
    name: "Curaçao",
    flag: "🇨🇼",
    countryCodes: ["+599"],
  },
  {
    code: "CY",
    name: "Cyprus",
    flag: "🇨🇾",
    countryCodes: ["+357"],
  },
  {
    code: "CZ",
    name: "Czechia",
    flag: "🇨🇿",
    countryCodes: ["+420"],
  },
  {
    code: "DK",
    name: "Denmark",
    flag: "🇩🇰",
    countryCodes: ["+45"],
  },
  {
    code: "DJ",
    name: "Djibouti",
    flag: "🇩🇯",
    countryCodes: ["+253"],
  },
  {
    code: "DM",
    name: "Dominica",
    flag: "🇩🇲",
    countryCodes: ["+1"],
  },
  {
    code: "DO",
    name: "Dominican Republic",
    flag: "🇩🇴",
    countryCodes: ["+1"],
  },
  {
    code: "CD",
    name: "DR Congo",
    flag: "🇨🇩",
    countryCodes: ["+243"],
  },
  {
    code: "EC",
    name: "Ecuador",
    flag: "🇪🇨",
    countryCodes: ["+593"],
  },
  {
    code: "EG",
    name: "Egypt",
    flag: "🇪🇬",
    countryCodes: ["+20"],
  },
  {
    code: "SV",
    name: "El Salvador",
    flag: "🇸🇻",
    countryCodes: ["+503"],
  },
  {
    code: "GQ",
    name: "Equatorial Guinea",
    flag: "🇬🇶",
    countryCodes: ["+240"],
  },
  {
    code: "ER",
    name: "Eritrea",
    flag: "🇪🇷",
    countryCodes: ["+291"],
  },
  {
    code: "EE",
    name: "Estonia",
    flag: "🇪🇪",
    countryCodes: ["+372"],
  },
  {
    code: "SZ",
    name: "Eswatini",
    flag: "🇸🇿",
    countryCodes: ["+268"],
  },
  {
    code: "ET",
    name: "Ethiopia",
    flag: "🇪🇹",
    countryCodes: ["+251"],
  },
  {
    code: "FK",
    name: "Falkland Islands",
    flag: "🇫🇰",
    countryCodes: ["+500"],
  },
  {
    code: "FO",
    name: "Faroe Islands",
    flag: "🇫🇴",
    countryCodes: ["+298"],
  },
  {
    code: "FJ",
    name: "Fiji",
    flag: "🇫🇯",
    countryCodes: ["+679"],
  },
  {
    code: "FI",
    name: "Finland",
    flag: "🇫🇮",
    countryCodes: ["+358"],
  },
  {
    code: "FR",
    name: "France",
    flag: "🇫🇷",
    countryCodes: ["+33"],
  },
  {
    code: "GF",
    name: "French Guiana",
    flag: "🇬🇫",
    countryCodes: ["+594"],
  },
  {
    code: "PF",
    name: "French Polynesia",
    flag: "🇵🇫",
    countryCodes: ["+689"],
  },
  {
    code: "TF",
    name: "French Southern and Antarctic Lands",
    flag: "🇹🇫",
    countryCodes: ["+262"],
  },
  {
    code: "GA",
    name: "Gabon",
    flag: "🇬🇦",
    countryCodes: ["+241"],
  },
  {
    code: "GM",
    name: "Gambia",
    flag: "🇬🇲",
    countryCodes: ["+220"],
  },
  {
    code: "GE",
    name: "Georgia",
    flag: "🇬🇪",
    countryCodes: ["+995"],
  },
  {
    code: "DE",
    name: "Germany",
    flag: "🇩🇪",
    countryCodes: ["+49"],
  },
  {
    code: "GH",
    name: "Ghana",
    flag: "🇬🇭",
    countryCodes: ["+233"],
  },
  {
    code: "GI",
    name: "Gibraltar",
    flag: "🇬🇮",
    countryCodes: ["+350"],
  },
  {
    code: "GR",
    name: "Greece",
    flag: "🇬🇷",
    countryCodes: ["+30"],
  },
  {
    code: "GL",
    name: "Greenland",
    flag: "🇬🇱",
    countryCodes: ["+299"],
  },
  {
    code: "GD",
    name: "Grenada",
    flag: "🇬🇩",
    countryCodes: ["+1"],
  },
  {
    code: "GP",
    name: "Guadeloupe",
    flag: "🇬🇵",
    countryCodes: ["+590"],
  },
  {
    code: "GU",
    name: "Guam",
    flag: "🇬🇺",
    countryCodes: ["+1"],
  },
  {
    code: "GT",
    name: "Guatemala",
    flag: "🇬🇹",
    countryCodes: ["+502"],
  },
  {
    code: "GG",
    name: "Guernsey",
    flag: "🇬🇬",
    countryCodes: ["+44"],
  },
  {
    code: "GN",
    name: "Guinea",
    flag: "🇬🇳",
    countryCodes: ["+224"],
  },
  {
    code: "GW",
    name: "Guinea-Bissau",
    flag: "🇬🇼",
    countryCodes: ["+245"],
  },
  {
    code: "GY",
    name: "Guyana",
    flag: "🇬🇾",
    countryCodes: ["+592"],
  },
  {
    code: "HT",
    name: "Haiti",
    flag: "🇭🇹",
    countryCodes: ["+509"],
  },
  {
    code: "HN",
    name: "Honduras",
    flag: "🇭🇳",
    countryCodes: ["+504"],
  },
  {
    code: "HK",
    name: "Hong Kong",
    flag: "🇭🇰",
    countryCodes: ["+852"],
  },
  {
    code: "HU",
    name: "Hungary",
    flag: "🇭🇺",
    countryCodes: ["+36"],
  },
  {
    code: "IS",
    name: "Iceland",
    flag: "🇮🇸",
    countryCodes: ["+354"],
  },
  {
    code: "IN",
    name: "India",
    flag: "🇮🇳",
    countryCodes: ["+91"],
  },
  {
    code: "ID",
    name: "Indonesia",
    flag: "🇮🇩",
    countryCodes: ["+62"],
  },
  {
    code: "IR",
    name: "Iran",
    flag: "🇮🇷",
    countryCodes: ["+98"],
  },
  {
    code: "IQ",
    name: "Iraq",
    flag: "🇮🇶",
    countryCodes: ["+964"],
  },
  {
    code: "IE",
    name: "Ireland",
    flag: "🇮🇪",
    countryCodes: ["+353"],
  },
  {
    code: "IM",
    name: "Isle of Man",
    flag: "🇮🇲",
    countryCodes: ["+44"],
  },
  {
    code: "IL",
    name: "Israel",
    flag: "🇮🇱",
    countryCodes: ["+972"],
  },
  {
    code: "IT",
    name: "Italy",
    flag: "🇮🇹",
    countryCodes: ["+39"],
  },
  {
    code: "CI",
    name: "Ivory Coast",
    flag: "🇨🇮",
    countryCodes: ["+225"],
  },
  {
    code: "JM",
    name: "Jamaica",
    flag: "🇯🇲",
    countryCodes: ["+1"],
  },
  {
    code: "JE",
    name: "Jersey",
    flag: "🇯🇪",
    countryCodes: ["+44"],
  },
  {
    code: "JO",
    name: "Jordan",
    flag: "🇯🇴",
    countryCodes: ["+962"],
  },
  {
    code: "KZ",
    name: "Kazakhstan",
    flag: "🇰🇿",
    countryCodes: ["+7"],
  },
  {
    code: "KE",
    name: "Kenya",
    flag: "🇰🇪",
    countryCodes: ["+254"],
  },
  {
    code: "KI",
    name: "Kiribati",
    flag: "🇰🇮",
    countryCodes: ["+686"],
  },
  {
    code: "XK",
    name: "Kosovo",
    flag: "🇽🇰",
    countryCodes: ["+383"],
  },
  {
    code: "KW",
    name: "Kuwait",
    flag: "🇰🇼",
    countryCodes: ["+965"],
  },
  {
    code: "KG",
    name: "Kyrgyzstan",
    flag: "🇰🇬",
    countryCodes: ["+996"],
  },
  {
    code: "LA",
    name: "Laos",
    flag: "🇱🇦",
    countryCodes: ["+856"],
  },
  {
    code: "LV",
    name: "Latvia",
    flag: "🇱🇻",
    countryCodes: ["+371"],
  },
  {
    code: "LB",
    name: "Lebanon",
    flag: "🇱🇧",
    countryCodes: ["+961"],
  },
  {
    code: "LS",
    name: "Lesotho",
    flag: "🇱🇸",
    countryCodes: ["+266"],
  },
  {
    code: "LR",
    name: "Liberia",
    flag: "🇱🇷",
    countryCodes: ["+231"],
  },
  {
    code: "LY",
    name: "Libya",
    flag: "🇱🇾",
    countryCodes: ["+218"],
  },
  {
    code: "LI",
    name: "Liechtenstein",
    flag: "🇱🇮",
    countryCodes: ["+423"],
  },
  {
    code: "LT",
    name: "Lithuania",
    flag: "🇱🇹",
    countryCodes: ["+370"],
  },
  {
    code: "LU",
    name: "Luxembourg",
    flag: "🇱🇺",
    countryCodes: ["+352"],
  },
  {
    code: "MO",
    name: "Macau",
    flag: "🇲🇴",
    countryCodes: ["+853"],
  },
  {
    code: "MG",
    name: "Madagascar",
    flag: "🇲🇬",
    countryCodes: ["+261"],
  },
  {
    code: "MW",
    name: "Malawi",
    flag: "🇲🇼",
    countryCodes: ["+265"],
  },
  {
    code: "MY",
    name: "Malaysia",
    flag: "🇲🇾",
    countryCodes: ["+60"],
  },
  {
    code: "MV",
    name: "Maldives",
    flag: "🇲🇻",
    countryCodes: ["+960"],
  },
  {
    code: "ML",
    name: "Mali",
    flag: "🇲🇱",
    countryCodes: ["+223"],
  },
  {
    code: "MT",
    name: "Malta",
    flag: "🇲🇹",
    countryCodes: ["+356"],
  },
  {
    code: "MH",
    name: "Marshall Islands",
    flag: "🇲🇭",
    countryCodes: ["+692"],
  },
  {
    code: "MQ",
    name: "Martinique",
    flag: "🇲🇶",
    countryCodes: ["+596"],
  },
  {
    code: "MR",
    name: "Mauritania",
    flag: "🇲🇷",
    countryCodes: ["+222"],
  },
  {
    code: "MU",
    name: "Mauritius",
    flag: "🇲🇺",
    countryCodes: ["+230"],
  },
  {
    code: "YT",
    name: "Mayotte",
    flag: "🇾🇹",
    countryCodes: ["+262"],
  },
  {
    code: "MX",
    name: "Mexico",
    flag: "🇲🇽",
    countryCodes: ["+52"],
  },
  {
    code: "FM",
    name: "Micronesia",
    flag: "🇫🇲",
    countryCodes: ["+691"],
  },
  {
    code: "MD",
    name: "Moldova",
    flag: "🇲🇩",
    countryCodes: ["+373"],
  },
  {
    code: "MC",
    name: "Monaco",
    flag: "🇲🇨",
    countryCodes: ["+377"],
  },
  {
    code: "MN",
    name: "Mongolia",
    flag: "🇲🇳",
    countryCodes: ["+976"],
  },
  {
    code: "ME",
    name: "Montenegro",
    flag: "🇲🇪",
    countryCodes: ["+382"],
  },
  {
    code: "MS",
    name: "Montserrat",
    flag: "🇲🇸",
    countryCodes: ["+1"],
  },
  {
    code: "MA",
    name: "Morocco",
    flag: "🇲🇦",
    countryCodes: ["+212"],
  },
  {
    code: "MZ",
    name: "Mozambique",
    flag: "🇲🇿",
    countryCodes: ["+258"],
  },
  {
    code: "MM",
    name: "Myanmar",
    flag: "🇲🇲",
    countryCodes: ["+95"],
  },
  {
    code: "NA",
    name: "Namibia",
    flag: "🇳🇦",
    countryCodes: ["+264"],
  },
  {
    code: "NR",
    name: "Nauru",
    flag: "🇳🇷",
    countryCodes: ["+674"],
  },
  {
    code: "NP",
    name: "Nepal",
    flag: "🇳🇵",
    countryCodes: ["+977"],
  },
  {
    code: "NL",
    name: "Netherlands",
    flag: "🇳🇱",
    countryCodes: ["+31"],
  },
  {
    code: "NC",
    name: "New Caledonia",
    flag: "🇳🇨",
    countryCodes: ["+687"],
  },
  {
    code: "NZ",
    name: "New Zealand",
    flag: "🇳🇿",
    countryCodes: ["+64"],
  },
  {
    code: "NI",
    name: "Nicaragua",
    flag: "🇳🇮",
    countryCodes: ["+505"],
  },
  {
    code: "NE",
    name: "Niger",
    flag: "🇳🇪",
    countryCodes: ["+227"],
  },
  {
    code: "NG",
    name: "Nigeria",
    flag: "🇳🇬",
    countryCodes: ["+234"],
  },
  {
    code: "NU",
    name: "Niue",
    flag: "🇳🇺",
    countryCodes: ["+683"],
  },
  {
    code: "NF",
    name: "Norfolk Island",
    flag: "🇳🇫",
    countryCodes: ["+672"],
  },
  {
    code: "KP",
    name: "North Korea",
    flag: "🇰🇵",
    countryCodes: ["+850"],
  },
  {
    code: "MK",
    name: "North Macedonia",
    flag: "🇲🇰",
    countryCodes: ["+389"],
  },
  {
    code: "MP",
    name: "Northern Mariana Islands",
    flag: "🇲🇵",
    countryCodes: ["+1"],
  },
  {
    code: "NO",
    name: "Norway",
    flag: "🇳🇴",
    countryCodes: ["+47"],
  },
  {
    code: "OM",
    name: "Oman",
    flag: "🇴🇲",
    countryCodes: ["+968"],
  },
  {
    code: "PK",
    name: "Pakistan",
    flag: "🇵🇰",
    countryCodes: ["+92"],
  },
  {
    code: "PW",
    name: "Palau",
    flag: "🇵🇼",
    countryCodes: ["+680"],
  },
  {
    code: "PS",
    name: "Palestine",
    flag: "🇵🇸",
    countryCodes: ["+970"],
  },
  {
    code: "PA",
    name: "Panama",
    flag: "🇵🇦",
    countryCodes: ["+507"],
  },
  {
    code: "PG",
    name: "Papua New Guinea",
    flag: "🇵🇬",
    countryCodes: ["+675"],
  },
  {
    code: "PY",
    name: "Paraguay",
    flag: "🇵🇾",
    countryCodes: ["+595"],
  },
  {
    code: "PE",
    name: "Peru",
    flag: "🇵🇪",
    countryCodes: ["+51"],
  },
  {
    code: "PH",
    name: "Philippines",
    flag: "🇵🇭",
    countryCodes: ["+63"],
  },
  {
    code: "PN",
    name: "Pitcairn Islands",
    flag: "🇵🇳",
    countryCodes: ["+64"],
  },
  {
    code: "PL",
    name: "Poland",
    flag: "🇵🇱",
    countryCodes: ["+48"],
  },
  {
    code: "PT",
    name: "Portugal",
    flag: "🇵🇹",
    countryCodes: ["+351"],
  },
  {
    code: "PR",
    name: "Puerto Rico",
    flag: "🇵🇷",
    countryCodes: ["+1"],
  },
  {
    code: "QA",
    name: "Qatar",
    flag: "🇶🇦",
    countryCodes: ["+974"],
  },
  {
    code: "CG",
    name: "Republic of the Congo",
    flag: "🇨🇬",
    countryCodes: ["+242"],
  },
  {
    code: "RE",
    name: "Réunion",
    flag: "🇷🇪",
    countryCodes: ["+262"],
  },
  {
    code: "RO",
    name: "Romania",
    flag: "🇷🇴",
    countryCodes: ["+40"],
  },
  {
    code: "RU",
    name: "Russia",
    flag: "🇷🇺",
    countryCodes: ["+7"],
  },
  {
    code: "RW",
    name: "Rwanda",
    flag: "🇷🇼",
    countryCodes: ["+250"],
  },
  {
    code: "BL",
    name: "Saint Barthélemy",
    flag: "🇧🇱",
    countryCodes: ["+590"],
  },
  {
    code: "SH",
    name: "Saint Helena, Ascension and Tristan da Cunha",
    flag: "🇸🇭",
    countryCodes: ["+290"],
  },
  {
    code: "KN",
    name: "Saint Kitts and Nevis",
    flag: "🇰🇳",
    countryCodes: ["+1"],
  },
  {
    code: "LC",
    name: "Saint Lucia",
    flag: "🇱🇨",
    countryCodes: ["+1"],
  },
  {
    code: "MF",
    name: "Saint Martin",
    flag: "🇲🇫",
    countryCodes: ["+590"],
  },
  {
    code: "PM",
    name: "Saint Pierre and Miquelon",
    flag: "🇵🇲",
    countryCodes: ["+508"],
  },
  {
    code: "VC",
    name: "Saint Vincent and the Grenadines",
    flag: "🇻🇨",
    countryCodes: ["+1"],
  },
  {
    code: "WS",
    name: "Samoa",
    flag: "🇼🇸",
    countryCodes: ["+685"],
  },
  {
    code: "SM",
    name: "San Marino",
    flag: "🇸🇲",
    countryCodes: ["+378"],
  },
  {
    code: "ST",
    name: "São Tomé and Príncipe",
    flag: "🇸🇹",
    countryCodes: ["+239"],
  },
  {
    code: "SA",
    name: "Saudi Arabia",
    flag: "🇸🇦",
    countryCodes: ["+966"],
  },
  {
    code: "SN",
    name: "Senegal",
    flag: "🇸🇳",
    countryCodes: ["+221"],
  },
  {
    code: "RS",
    name: "Serbia",
    flag: "🇷🇸",
    countryCodes: ["+381"],
  },
  {
    code: "SC",
    name: "Seychelles",
    flag: "🇸🇨",
    countryCodes: ["+248"],
  },
  {
    code: "SL",
    name: "Sierra Leone",
    flag: "🇸🇱",
    countryCodes: ["+232"],
  },
  {
    code: "SG",
    name: "Singapore",
    flag: "🇸🇬",
    countryCodes: ["+65"],
  },
  {
    code: "SX",
    name: "Sint Maarten",
    flag: "🇸🇽",
    countryCodes: ["+1"],
  },
  {
    code: "SK",
    name: "Slovakia",
    flag: "🇸🇰",
    countryCodes: ["+421"],
  },
  {
    code: "SI",
    name: "Slovenia",
    flag: "🇸🇮",
    countryCodes: ["+386"],
  },
  {
    code: "SB",
    name: "Solomon Islands",
    flag: "🇸🇧",
    countryCodes: ["+677"],
  },
  {
    code: "SO",
    name: "Somalia",
    flag: "🇸🇴",
    countryCodes: ["+252"],
  },
  {
    code: "ZA",
    name: "South Africa",
    flag: "🇿🇦",
    countryCodes: ["+27"],
  },
  {
    code: "GS",
    name: "South Georgia",
    flag: "🇬🇸",
    countryCodes: ["+500"],
  },
  {
    code: "SS",
    name: "South Sudan",
    flag: "🇸🇸",
    countryCodes: ["+211"],
  },
  {
    code: "ES",
    name: "Spain",
    flag: "🇪🇸",
    countryCodes: ["+34"],
  },
  {
    code: "LK",
    name: "Sri Lanka",
    flag: "🇱🇰",
    countryCodes: ["+94"],
  },
  {
    code: "SD",
    name: "Sudan",
    flag: "🇸🇩",
    countryCodes: ["+249"],
  },
  {
    code: "SR",
    name: "Suriname",
    flag: "🇸🇷",
    countryCodes: ["+597"],
  },
  {
    code: "SJ",
    name: "Svalbard and Jan Mayen",
    flag: "🇸🇯",
    countryCodes: ["+4779"],
  },
  {
    code: "SE",
    name: "Sweden",
    flag: "🇸🇪",
    countryCodes: ["+46"],
  },
  {
    code: "CH",
    name: "Switzerland",
    flag: "🇨🇭",
    countryCodes: ["+41"],
  },
  {
    code: "SY",
    name: "Syria",
    flag: "🇸🇾",
    countryCodes: ["+963"],
  },
  {
    code: "TW",
    name: "Taiwan",
    flag: "🇹🇼",
    countryCodes: ["+886"],
  },
  {
    code: "TJ",
    name: "Tajikistan",
    flag: "🇹🇯",
    countryCodes: ["+992"],
  },
  {
    code: "TZ",
    name: "Tanzania",
    flag: "🇹🇿",
    countryCodes: ["+255"],
  },
  {
    code: "TH",
    name: "Thailand",
    flag: "🇹🇭",
    countryCodes: ["+66"],
  },
  {
    code: "TL",
    name: "Timor-Leste",
    flag: "🇹🇱",
    countryCodes: ["+670"],
  },
  {
    code: "TG",
    name: "Togo",
    flag: "🇹🇬",
    countryCodes: ["+228"],
  },
  {
    code: "TK",
    name: "Tokelau",
    flag: "🇹🇰",
    countryCodes: ["+690"],
  },
  {
    code: "TO",
    name: "Tonga",
    flag: "🇹🇴",
    countryCodes: ["+676"],
  },
  {
    code: "TT",
    name: "Trinidad and Tobago",
    flag: "🇹🇹",
    countryCodes: ["+1"],
  },
  {
    code: "TN",
    name: "Tunisia",
    flag: "🇹🇳",
    countryCodes: ["+216"],
  },
  {
    code: "TR",
    name: "Turkey",
    flag: "🇹🇷",
    countryCodes: ["+90"],
  },
  {
    code: "TM",
    name: "Turkmenistan",
    flag: "🇹🇲",
    countryCodes: ["+993"],
  },
  {
    code: "TC",
    name: "Turks and Caicos Islands",
    flag: "🇹🇨",
    countryCodes: ["+1"],
  },
  {
    code: "TV",
    name: "Tuvalu",
    flag: "🇹🇻",
    countryCodes: ["+688"],
  },
  {
    code: "UG",
    name: "Uganda",
    flag: "🇺🇬",
    countryCodes: ["+256"],
  },
  {
    code: "UA",
    name: "Ukraine",
    flag: "🇺🇦",
    countryCodes: ["+380"],
  },
  {
    code: "AE",
    name: "United Arab Emirates",
    flag: "🇦🇪",
    countryCodes: ["+971"],
  },
  {
    code: "GB",
    name: "United Kingdom",
    flag: "🇬🇧",
    countryCodes: ["+44"],
  },
  {
    code: "UM",
    name: "United States Minor Outlying Islands",
    flag: "🇺🇲",
    countryCodes: ["+268"],
  },
  {
    code: "VI",
    name: "United States Virgin Islands",
    flag: "🇻🇮",
    countryCodes: ["+1"],
  },
  {
    code: "UY",
    name: "Uruguay",
    flag: "🇺🇾",
    countryCodes: ["+598"],
  },
  {
    code: "UZ",
    name: "Uzbekistan",
    flag: "🇺🇿",
    countryCodes: ["+998"],
  },
  {
    code: "VU",
    name: "Vanuatu",
    flag: "🇻🇺",
    countryCodes: ["+678"],
  },
  {
    code: "VA",
    name: "Vatican City",
    flag: "🇻🇦",
    countryCodes: ["+379"],
  },
  {
    code: "VE",
    name: "Venezuela",
    flag: "🇻🇪",
    countryCodes: ["+58"],
  },
  {
    code: "WF",
    name: "Wallis and Futuna",
    flag: "🇼🇫",
    countryCodes: ["+681"],
  },
  {
    code: "EH",
    name: "Western Sahara",
    flag: "🇪🇭",
    countryCodes: ["+212"],
  },
  {
    code: "YE",
    name: "Yemen",
    flag: "🇾🇪",
    countryCodes: ["+967"],
  },
  {
    code: "ZM",
    name: "Zambia",
    flag: "🇿🇲",
    countryCodes: ["+260"],
  },
  {
    code: "ZW",
    name: "Zimbabwe",
    flag: "🇿🇼",
    countryCodes: ["+263"],
  },
];
