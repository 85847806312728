/* eslint-disable jsx-a11y/anchor-is-valid */
import { useIntl } from "react-intl";
import clsx from "clsx";
import PhoneIcon from "@mui/icons-material/Phone";
import dayjs from "dayjs";
import { IcNow } from "_metronic/assets/svgs/Now";
import { Tag } from "antd";
import { User } from "./core/_models";
//import ControlledAccordions from "./components/AccordionQuestion";
import { IcNoData } from "_metronic/assets/svgs/NoData";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import Fancybox from "app/_components/Fancybox";
import { KTIcon, toAbsoluteUrlENV } from "_metronic/helpers";

export const GENDER = {
  MALE: "Male",
  FEMALE: "Female",
  OTHER: "Other",
};

const color = [
  "processing",
  "success",
  "error",
  "warning",
  "magenta",
  "red",
  "volcano",
  "orange",
  "gold",
  "lime",
  "green",
  "cyan",
  "blue",
  "geekblue",
  "purple",
];

const TagProfile = ({ values }: { values: any[] }) => {
  return (
    <div>
      {!values?.length
        ? "-"
        : values.map((value, index) => (
            <Tag key={index} color={color[index]}>
              {value.content?.value || "-"}
            </Tag>
          ))}
    </div>
  );
};

const DevicesTagProfile = ({ values }: { values: any[] }) => {
  return (
    <div>
      {!values?.length
        ? "-"
        : values.map((value, index) => {
            if (value?.deviceOs && value?.deviceModel)
              return (
                <Tag key={index} color={color[index]} className="mb-2">
                  {value?.deviceOs + "-" + value?.deviceModel || "-"}
                </Tag>
              );
            else return <></>;
          })}
    </div>
  );
};

const ProfileHeader = ({ user }: { user: any }) => {
  const intl = useIntl();
  const {
    nickName,
    gender,
    birthDay,
    createdAt,
    phoneNumber,
    wallet,
    countryCode,
    platform,
    height,
    mbti,
    devices,
    hobbiesCustomUsers,
    hobbyUsers,
    bodyUsers,
    pictures,
    questionsUsers,
  } = user || {};

  const profile_detail = [
    {
      label: "DOB",
      value: birthDay ? dayjs(birthDay).format("DD-MM-YYYY") : "-",
    },
    /* {
      label: "PRE-RESERVATIONS",
      value: "",
    }, */
    {
      label: "BODY-TYPE",
      value: <TagProfile values={bodyUsers} />,
    },
    {
      label: "HEIGHT",
      value: height ? `${height} cm` : "-",
    },
    {
      label: "MBTI",
      value: mbti,
    },
    {
      label: "HOBBIES",
      value: <TagProfile values={hobbiesCustomUsers?.concat(hobbyUsers)} />,
    },
    {
      label: "DEVICE",
      value: <DevicesTagProfile values={devices} />,
    },
  ];

  return (
    <div
      className="my-4 p-0"
      style={{ height: "calc(100vh - 160px)", overflow: "auto" }}
    >
      <div className="card mb-6">
        <div className="card-body d-flex align-items-start flex-wrap flex-column flex-sm-row ">
          <div className="me-7">
            <div className="symbol symbol-160px overflow-hidden ">
              <div className={clsx("symbol-label fs-3")}>
                <img
                  alt="user"
                  src={toAbsoluteUrlENV(pictures?.[0] || "")}
                  onError={(e) => {
                    e.currentTarget.src = "/media/avatars/blank.png";
                  }}
                  className="h-100"
                />
              </div>
            </div>
          </div>
          <div className="d-flex flex-column fw-semibold">
            <div className="text-gray-800 fs-2 fw-bolder mb-2">
              {nickName ? `${nickName} ` : "No name"}
            </div>
            <div className="d-flex align-items-center text-gray-500 me-5 mb-2 gap-2 fw-bolder">
              <PhoneIcon fontSize="small" />
              {phoneNumber ? countryCode + " " + phoneNumber : "-"}
            </div>
            <div className="d-flex align-items-center text-gray-500 me-5 mb-2 gap-2 fw-bolder">
              <PersonRoundedIcon fontSize="small" />
              {gender ? intl.formatMessage({ id: gender }) : "-"}
            </div>

            <span className="d-flex align-items-center text-gray-500 me-5 mb-2 gap-2 fw-bolder">
              <KTIcon iconName="calendar" className="fs-4 w-4" />
              {intl.formatMessage({ id: "REGISTER-DATE" })}:{" "}
              {dayjs(createdAt).format("YYYY-MM-DD HH:mm:ss") || "-"}
            </span>
            <div className="d-flex align-items-center text-gray-500 me-5 mb-2 gap-2 fw-bolder">
              <IcNow width="16" />
              {wallet?.balance || 0}
            </div>
          </div>
        </div>
        {/*  <TabsProfileInfo /> */}
      </div>
      <div className="card mb-6">
        <div className="card-header">
          <div className="card-title">
            <h3 className="fw-bolder m-0">
              {intl.formatMessage({ id: "PROFILE-DETAILS" })}
            </h3>
          </div>
        </div>
        <div className="card-body">
          {profile_detail.map((detail) => {
            return (
              <div className="row mb-7">
                <div
                  className="col-lg-4 fw-bold text-muted"
                  style={{ color: "#475467" }}
                >
                  {intl.formatMessage({ id: detail.label })}
                </div>
                <div className="col-lg-8">
                  <span className="fw-bolder fs-6 text-gray-900">
                    {detail.value || "-"}
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="card mb-8">
        <div className="card-header">
          <div className="card-title">
            <h3 className="fw-bolder m-0">
              {intl.formatMessage({ id: "PROFILE-PICTURES" })}
            </h3>
          </div>
        </div>
        <div className="card-body d-flex gap-2 overflow-auto">
          {!!pictures?.length ? (
            <Fancybox
              options={{
                Carousel: {
                  infinite: true,
                },
              }}
              key={Math.random()}
              className="d-flex gap-4"
            >
              {pictures?.map((picture: any) => (
                <a
                  data-fancybox="gallery"
                  href={toAbsoluteUrlENV(picture || "")}
                  key={Math.random()}
                >
                  <img
                    alt="user"
                    src={toAbsoluteUrlENV(picture || "")}
                    onError={(e) => {
                      e.currentTarget.src = "/media/avatars/blank.png";
                    }}
                    className="h-100px rounded-4 mw-100"
                  />
                </a>
              ))}
            </Fancybox>
          ) : (
            <IcNoData height={100} className="mx-auto" />
          )}
        </div>
      </div>
      {/* <div className="card">
        <div className="card-header">
          <div className="card-title">
            <h3 className="fw-bolder m-0">
              {" "}
              {intl.formatMessage({ id: "QUESTIONS" })}
            </h3>
          </div>
        </div>
         <div className="card-body d-flex gap-2 overflow-auto mt-3">
          <ControlledAccordions data={questionsUsers} />
        </div>
      </div> */}
    </div>
  );
};

export { ProfileHeader };
