import { Suspense } from "react";
import { Outlet } from "react-router-dom";
import { I18nProvider } from "../_metronic/i18n/i18nProvider";
import { LayoutProvider, LayoutSplashScreen } from "../_metronic/layout/core";
import { MasterInit } from "../_metronic/layout/MasterInit";
import { AuthInit } from "./_modules/auth";
import { ChatSDKProvider } from "app/contexts/chatSDKContext";
import { ChatProvider } from "./contexts/chat";

const App = () => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <AuthInit>
            <ChatProvider>
              <ChatSDKProvider>
                <Outlet />
                <MasterInit />
              </ChatSDKProvider>
            </ChatProvider>
          </AuthInit>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  );
};

export { App };
