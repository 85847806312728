import { toAbsoluteUrlENV } from "_metronic/helpers";
import { useChat } from "app/contexts/chat";
import { ChatSDKContext } from "app/contexts/chatSDKContext";
import { useContext, useEffect, useState } from "react";
import male_icon from "_metronic/assets/svgs/male-icon.png";
import female_icon from "_metronic/assets/svgs/female-icon.png";
import { ALLCOUNTRIES } from "app/constants";
import { Link } from "react-router-dom";
import { Skeleton, Spin, Tooltip, notification } from "antd";
import { convertToHCM, truncateText } from "app/utils";

export default function HunterList({
  currentHunter,
  setCurrentHunter,
  setShowProfileById,
}: any) {
  const { listFemale, updateHunterAndMoveToTop, setListFemale } =
    useContext<any>(ChatSDKContext);
  const { message: incommingMess } = useChat();

  const [isLoading, setIsLoading] = useState(true);

  //console.log(listFemale);

  useEffect(() => {
    //nhan hoac gui tin nhan (tin nhan moi nhat) thi len dau danh sach
    const incommingHunterInList = listFemale?.find(
      (i: any) =>
        incommingMess?.receiverId == i?.externalId ||
        incommingMess?.author?.id == i?.externalId
    );

    if (!incommingHunterInList) return;

    const isCurrentHunter =
      currentHunter?.externalId == incommingMess?.receiverId ||
      currentHunter?.externalId == incommingMess?.author?.id;

    incommingHunterInList.lastMessageContent = incommingMess?.content;

    if (!isCurrentHunter)
      incommingHunterInList.countUnread = incommingHunterInList.countUnread + 1;

    updateHunterAndMoveToTop(incommingHunterInList);
  }, [incommingMess]);

  useEffect(() => {
    if (listFemale.length) setIsLoading(false);
    if (!currentHunter) {
      setCurrentHunter(listFemale[0]);
    } else
      setCurrentHunter(
        listFemale.find((i: any) => i?.externalId == currentHunter?.externalId)
      );
  }, [listFemale]);

  useEffect(() => {
    if (incommingMess?.author.id == currentHunter?.externalId) return;
    if (!("Notification" in window)) {
      alert("This browser does not support desktop notification");
    } else if (Notification.permission === "granted") {
      new Notification("Right now", {
        body: incommingMess?.content || "You got a new message",
      });
    } else if (Notification.permission !== "denied") {
      Notification.requestPermission().then(function (permission) {
        if (permission === "granted") {
          new Notification("Rightnow", {
            body: "You got a new message",
          });
        }
      });
    }
  }, [incommingMess]);

  function updateSocketIntanceByStatus(userId: string, socket: any) {
    const indexUser = listFemale?.findIndex((i: any) => i?.id == userId);

    if (indexUser >= 0) {
      const cloneFemale = [...listFemale];
      cloneFemale[indexUser].socket.connected = socket.connected;

      setListFemale(cloneFemale);
    }
  }

  useEffect(() => {
    if (!listFemale?.length) return;
    listFemale.forEach((i: any) => {
      const socket = i?.socket;
      if (!socket) return;
      socket.on("connect", () => {
        console.log("kết nối hoàn tất");
        updateSocketIntanceByStatus(i?.id, socket);
      });

      socket.on("disconnect", () => {
        console.log("ngắt kết nối");
        updateSocketIntanceByStatus(i?.id, socket);
      });
    });
  }, [listFemale?.length]);

  return (
    <div className="card card-flush" style={{ height: "100%" }}>
      <div className="card-header">
        <div className="card-title">
          <div className="symbol-group symbol-hover"></div>
          <div className="d-flex justify-content-center flex-column me-3">
            <a
              href="#"
              className="fs-4 fw-bolder text-gray-900 text-hover-primary lh-1"
            >
              Account List{" "}
              {listFemale?.length > 0 ? ` - (${listFemale?.length})` : null}
              <span className="ms-3 fs-6" id="loading-hunter-list">
                <Spin /> Loading...
              </span>
            </a>
          </div>
        </div>
      </div>
      <div
        className="card-body pt-5"
        id="kt_chat_contacts_body"
        style={{ paddingTop: 0, paddingBottom: 20 }}
      >
        <div
          className="scroll-y me-n5 pe-5"
          data-kt-scroll="true"
          data-kt-scroll-activate="{default: false, lg: true}"
          data-kt-scroll-max-height="auto"
          data-kt-scroll-dependencies="#kt_header, #kt_toolbar, #kt_footer, #kt_chat_contacts_header"
          data-kt-scroll-wrappers="#kt_content, #kt_chat_contacts_body"
          data-kt-scroll-offset="0px"
          style={{ height: "70vh" }}
        >
          {isLoading && (
            <>
              <Skeleton loading={true} active avatar />
              <Skeleton loading={true} active avatar />
              <Skeleton loading={true} active avatar />
            </>
          )}

          {!isLoading &&
            listFemale?.map((i: any, index: number) => {
              return (
                <>
                  <div
                    className="d-flex flex-stack py-4"
                    onClick={() => {
                      setCurrentHunter(listFemale[index]);
                    }}
                    style={
                      i?.externalId == currentHunter?.externalId
                        ? {
                            background: "#f3f3f3",
                          }
                        : {}
                    }
                  >
                    <div className="d-flex">
                      <div
                        onClick={() => setShowProfileById(i?.id)}
                        className="cursor-pointer symbol symbol-45px symbol-circle"
                      >
                        {i?.avatar ? (
                          <img alt="Pic" src={toAbsoluteUrlENV(i?.avatar)} />
                        ) : (
                          <span className="symbol-label bg-light-danger text-danger fs-6 fw-bolder">
                            {i?.nickName?.substring(0, 1)}
                          </span>
                        )}
                      </div>

                      <div className="ms-5">
                        <div className="fs-5 fw-bolder text-gray-900 text-hover-primary cursor-pointer d-flex align-items-center gap-3">
                          <span onClick={() => setShowProfileById(i?.id)}>
                            {i?.nickName}{" "}
                          </span>

                          {i?.socket?.connected ? (
                            <div>
                              <Tooltip
                                placement="top"
                                title="Connected socket"
                                color="green"
                              >
                                <span
                                  className="badge badge-circle badge-success bg-success"
                                  style={{ height: 7, width: 7 }}
                                ></span>
                              </Tooltip>
                              {/*  <span
                                className="fs-9 text-primary ms-2"
                                onClick={async () => {
                                  const socket = i?.socket.disconnect();

                                  socket.on("connect", () => {
                                    console.log("kết nối thủ công hoàn tất");
                                    updateSocketIntanceByStatus(i?.id, socket);
                                  });

                                  socket.on("disconnect", () => {
                                    console.log("kết nối thủ công thất bại");
                                    updateSocketIntanceByStatus(i?.id, socket);
                                  });
                                }}
                              >
                                Disconnect
                              </span> */}
                            </div>
                          ) : (
                            <div className="d-flex align-items-center">
                              <Tooltip
                                placement="top"
                                title="Disconnected socket"
                                color="red"
                              >
                                <span
                                  className="badge badge-circle badge-light-danger bg-danger"
                                  style={{ height: 7, width: 7 }}
                                ></span>
                              </Tooltip>
                              <span
                                className="fs-9 text-primary ms-2"
                                onClick={async () => {
                                  const connectDiv = document.getElementById(
                                    `connect-div-${i?.id}`
                                  );
                                  if (connectDiv)
                                    connectDiv.innerText = "Connecting...";

                                  const socket = i?.socket.connect();

                                  socket.on("connect", () => {
                                    console.log("kết nối thủ công hoàn tất");
                                    updateSocketIntanceByStatus(i?.id, socket);
                                  });

                                  socket.on("disconnect", () => {
                                    console.log("kết nối thủ công thất bại");
                                    updateSocketIntanceByStatus(i?.id, socket);
                                  });
                                }}
                              >
                                <span id={`connect-div-${i?.id}`}>
                                  {" "}
                                  Connect{" "}
                                </span>
                              </span>
                            </div>
                          )}
                        </div>{" "}
                        <div
                          className="text-gray-400"
                          style={{
                            display: "flex",
                            gap: 5,
                            alignItems: "center",
                          }}
                        >
                          <div style={{ fontSize: 13 }}>{i?.age}</div>
                          <div style={{ fontSize: 15 }}>
                            {
                              ALLCOUNTRIES.find((c: any) => {
                                return c?.countryCodes
                                  ?.toString()
                                  ?.toLowerCase()
                                  .includes(i?.countryCode);
                              })?.flag
                            }
                          </div>
                          <img
                            src={i?.gender == "MALE" ? male_icon : female_icon}
                            style={{ width: 12, height: 12 }}
                          />

                          {i?.rawRightnowInfo?.userJob?.[0]?.value?.en}
                        </div>
                        {i?.lastMessageContent && (
                          <>
                            <div className="fw-bold text-gray-400 fs-6">
                              {truncateText(i?.lastMessageContent)}
                            </div>
                            <div className="text-muted fs-8">
                              {convertToHCM(i?.lastMessageDate)}
                            </div>
                          </>
                        )}
                      </div>
                    </div>

                    <div className="d-flex flex-column align-items-end ms-2">
                      <span className="text-muted fs-7 mb-1">
                        {i?.countUnread > 0 ? (
                          <span className="badge badge-sm badge-circle badge-light-danger text-white bg-danger fs-8 p-4">{`${i.countUnread}`}</span>
                        ) : null}
                      </span>
                    </div>
                  </div>
                </>
              );
            })}
        </div>
      </div>
    </div>
  );
}
