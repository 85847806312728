import { Upload } from "antd";
import AlbumButton from "./AlbumButton";
import { SetPriceImageModal } from "./SetImagePriceModal";
import { useChat } from "app/contexts/chat";
import { useState } from "react";
import {
  saveImageChatToRightnowAlbum,
  uploadMulipleImages,
} from "app/contexts/chatSDKContext/api";
import { realtimeChat } from "app/contexts/chatSDKContext/socketEmit";
import { MessageModel } from "_metronic/helpers";
const REACT_APP_CHAT_SDK_CLIENT_ID = process.env.REACT_APP_CHAT_SDK_CLIENT_ID;

export default function ChatFooter({
  currentHunter,
  currentPrey,
  messages,
  setMessages,
  privateChatId,
}: any) {
  const [chatUpdateFlag, toggleChatUpdateFlat] = useState<boolean>(false);

  const [message, setMessage] = useState<string>("");
  const [tempImgs, setTempImgs] = useState<any>([]);
  const [tempAlbums, setTempAlbums] = useState<any>([]);

  const [isShowSetPriceModal, setIsShowSetPriceModal] = useState(false);

  const updateMessageLocal = async () => {
    const newMessage: MessageModel = {
      user: 3,
      type: "out",
      text: message,
      time: Date.now(),
      imagesInfo: tempImgs?.map((i: any) => {
        return {
          url: URL.createObjectURL(i.originFileObj),
          price: i?.price || 0,
          lock: true,
        };
      }),
      albums: tempAlbums?.map((i: any) => {
        try {
          return {
            title: i?.title,
            price: Number(i?.credit) || 0,
            lock: true,
            albumImages: i?.files,
          };
        } catch (error) {
          return { url: i?.path };
        }
      }),
    };
    const bufferMessages = messages;
    bufferMessages.push(newMessage);
    setMessages(bufferMessages);
    toggleChatUpdateFlat(!chatUpdateFlag);
    setMessage("");
    setTempImgs([]);
    setTempAlbums([]);
  };

  async function handleSendMess() {
    updateMessageLocal();

    const newMess: any = {
      receiverId: currentPrey?.externalId,
      content: message,
      clientId: REACT_APP_CHAT_SDK_CLIENT_ID,
      boxId: privateChatId,
      //privateChatId: privateChatId,
      participantId: currentPrey?.externalId,
      username: currentPrey?.username,
      avatar: currentPrey?.avatar,
    };

    // if (tempImgs?.length > 0)
    //   newMess.attachments = mapMultipleImageFileToURL(tempImgs);

    //async functions
    if (tempImgs?.length > 0) {
      const resURLs = await uploadMulipleImages(
        tempImgs,
        currentHunter?.chatSDKAccessToken,
        privateChatId,
        currentHunter.id
      );
      if (resURLs?.data?.length > 0) {
        newMess.attachments = resURLs?.data;
        saveImageChatToRightnowAlbum(currentHunter.id, newMess?.attachments);
      }
    }

    realtimeChat(currentHunter?.socket, newMess);
  }

  const onEnterPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      tempImgs?.length > 0 ? setIsShowSetPriceModal(true) : handleSendMess();
    }
  };

  return (
    <>
      <div className="card-footer pt-4 p-0" id={"kt_chat_messenger_footer"}>
        <div className="custom-upload-button">
          <Upload
            beforeUpload={() => false}
            listType="picture-card"
            multiple
            fileList={tempImgs}
            maxCount={10}
            onChange={(imgs) => {
              setTempImgs(imgs.fileList);
            }}
          >
            <button id="chooseImages">
              <i className="bi bi-paperclip fs-3"></i>
            </button>
          </Upload>
        </div>
        <div className="d-flex flex-stack justify-content-between">
          <button
            className="btn btn-sm btn-icon btn-active-light-primary me-1 d-flex align-items-center"
            type="button"
            data-bs-toggle="tooltip"
            title="Choose image"
            onClick={() => {
              document.getElementById("chooseImages")?.click();
            }}
          >
            <i className="bi bi-paperclip fs-3"></i>
          </button>
          <AlbumButton
            currentHunter={currentHunter}
            currentPrey={currentPrey}
            chatBoxId={privateChatId}
            tempAlbums={tempAlbums}
            setTempAlbums={setTempAlbums}
            updateMessageLocal={updateMessageLocal}
          />

          <textarea
            className="form-control form-control-flush me-10"
            rows={1}
            data-kt-element="input"
            placeholder="Type a message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onKeyDown={onEnterPress}
          ></textarea>
          <button
            className="btn btn-primary"
            type="button"
            data-kt-element="send"
            onClick={() => {
              tempImgs?.length > 0
                ? setIsShowSetPriceModal(true)
                : handleSendMess();
            }}
            disabled={message?.length == 0 && tempImgs?.length == 0}
          >
            Send
          </button>
        </div>
      </div>
      {isShowSetPriceModal && (
        <SetPriceImageModal
          handleClose={() => setIsShowSetPriceModal(false)}
          tempImgs={tempImgs}
          setTempImgs={setTempImgs}
          handleSendMess={handleSendMess}
        />
      )}
    </>
  );
}
