import axios from "axios";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  chatSDK_getListAccounts,
  chatSDK_getListFriends,
  getChatSDKInfoById,
  getMyGirlsListAPI,
  getTotalUnreadMessage,
} from "./api";
import { io } from "socket.io-client";
import registerSocketEvents from "./socketEvent";
import { useChat } from "../chat/core/Chat";
import { UserChatType } from "./type";
import { useAuth } from "app/_modules/auth";
import { DEFAULT_IMAGE_URL } from "_metronic/helpers";

const SOCKET_ENDPOINT = process.env.REACT_APP_SOCKET_ENDPOINT || "";

const initAuthContextPropsState: any = {
  listFemale: [],
  listHunter: [],
  listChatSDKInfoByHunter: [],
  listSocketByHunter: [],
  updateHunterCount: () => {},
  updateHunterAndMoveToTop: () => {},
  setListFemale: () => {},
};

// Creating a context
const ChatSDKContext = createContext(initAuthContextPropsState);

// Component that will provide the context value
const ChatSDKProvider = ({ children }: any) => {
  const { saveMessage } = useChat();
  const { auth } = useAuth();

  const [listFemale, setListFemale] = useState<any[]>([]);

  useEffect(() => {
    if (auth?.accessToken?.length > 0) initalListFemale();
  }, [auth]);

  async function getChatSDKInfo(girlList: any) {
    const listPromise = [];
    for (var i = 0; i <= girlList?.length - 1; i++) {
      const currentItem = girlList[i];
      const apiPromise = getChatSDKInfoById(girlList[i].id).then(
        async (res_chatsdk: any) => {
          if (res_chatsdk?.status == 200) {
            const chatSDKAccessToken = res_chatsdk?.data?.chatSDKAccessToken;

            //setup socket
            const socket = io(SOCKET_ENDPOINT, {
              auth: {
                token: chatSDKAccessToken || "",
              },
              transports: ["websocket"],
              reconnection: false,
            });
            registerSocketEvents(socket, saveMessage);

            //fortmat User
            const chatSDKUser: UserChatType = {
              id: currentItem?.id,
              externalId: currentItem?.externalId,
              avatar: currentItem?.pictures?.[0] || DEFAULT_IMAGE_URL,
              nickName: currentItem?.nickName,
              chatSDKAccessToken,
              socket,
              age: currentItem?.age,
              countryCode: currentItem?.countryCode,
              gender: currentItem?.gender,
              isMaleSubscribe: currentItem?.wallet?.rightnowPlusUntil,
              lastMessageContent: "",
              countUnread: 0,
              rightnowAccessToken: currentItem?.accessToken,
              rawRightnowInfo: currentItem,
            };

            return chatSDKUser;
          }
        }
      );

      listPromise.push(apiPromise);
    }
    const listResult = await Promise.all(listPromise);
    return listResult;
  }

  async function initalListFemale() {
    const res = await getMyGirlsListAPI();
    if (res?.status == 200) {
      const girlList = res?.data?.users;
      const female = await getChatSDKInfo(girlList);

      setListFemale(female); //cheating

      const girlListExternalIds = girlList?.map((i: any) => i?.externalId);
      const resChatSDKSortAccountList = await chatSDK_getListAccounts(
        girlListExternalIds,
        female[0]?.chatSDKAccessToken
      );

      const chatSDKSortAccountList = resChatSDKSortAccountList?.data?.data;

      //sort by unread
      for (var index = chatSDKSortAccountList?.length; index >= 0; index--) {
        const indexMatchInList = female?.findIndex(
          (i: any) => i?.externalId == chatSDKSortAccountList[index]?.userId
        );
        female.unshift(female.splice(indexMatchInList, 1)[0]);
        if (female[0]) {
          female[0].countUnread = chatSDKSortAccountList[index]?.totalUnread;
          female[0].lastMessageContent =
            chatSDKSortAccountList[index]?.newestMessage?.content;
          female[0].lastMessageDate =
            chatSDKSortAccountList[index]?.newestMessage?.timestamp;
        }
      }
      setListFemale(female);
      const connectDiv = document.getElementById(`loading-hunter-list`);
      if (connectDiv) connectDiv.innerText = "";
    }
  }

  //count
  function updateHunterCount(externalId: string, amount: number) {
    const femaleUpdatedCount = listFemale?.map((i: any) => {
      return {
        ...i,
        countUnread: i?.externalId == externalId ? amount : i?.countUnread,
      };
    });
    setListFemale(femaleUpdatedCount);
  }

  function updateHunterAndMoveToTop(updatedHunter: any) {
    const indexMatch = listFemale?.findIndex(
      (i: any) => i?.externalId == updatedHunter?.externalId
    );

    if (indexMatch >= 0) {
      let cloneArray = [...listFemale];
      cloneArray.unshift(cloneArray.splice(indexMatch, 1)[0]);
      cloneArray[0] = updatedHunter;
      setListFemale(cloneArray);
    }
  }

  return (
    <ChatSDKContext.Provider
      value={{
        listFemale,
        updateHunterCount,
        updateHunterAndMoveToTop,
        setListFemale,
      }}
    >
      {children}
    </ChatSDKContext.Provider>
  );
};

export { ChatSDKContext, ChatSDKProvider };
