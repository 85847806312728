import {
  DEFAULT_IMAGE_URL,
  KTIcon,
  toAbsoluteUrl,
  toAbsoluteUrlENV,
} from "_metronic/helpers";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import {
  chatSDK_getListFriends,
  getRightnowInfoById,
} from "app/contexts/chatSDKContext/api";
import { useChat } from "app/contexts/chat";
import { ChatSDKContext } from "app/contexts/chatSDKContext";
import { UserChatType } from "app/contexts/chatSDKContext/type";
import InfiniteScroll from "react-infinite-scroll-component";
import male_icon from "_metronic/assets/svgs/male-icon.png";
import female_icon from "_metronic/assets/svgs/female-icon.png";

import { Link } from "react-router-dom";
import { Select, Skeleton } from "antd";
import { convertToHCM, truncateText } from "app/utils";
import { ALLCOUNTRIES } from "app/constants";
import { setDefaultAvatarForTestAccount } from "app/utils/operatorUtils";

const API_URL = process.env.REACT_APP_API_URL;

export default function PreyList({
  currentHunter,
  currentPrey,
  setCurrentPrey,
  setFriendListCount,
  setShowProfileById,
}: any) {
  const [page, setPage] = useState(1);
  const [filterBoys, setFilterBoys] = useState<any>([]);
  const [listBoys, setListBoy] = useState<any>([]);
  const { message: incommingMess } = useChat();
  const { updateHunterCount } = useContext<any>(ChatSDKContext);
  const [totalCountUnread, setTotalCountUnread] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [subscriberSelection, setSubcriberSelection] = useState("all");

  useEffect(() => setFriendListCount(listBoys?.length), [listBoys]);

  useEffect(() => {
    if (currentHunter?.chatSDKAccessToken) {
      getBoyList(1);
      setPage(1);
    }
  }, [currentHunter?.chatSDKAccessToken]);

  useEffect(() => {
    getBoyList(page);
  }, [page]);

  async function getInfoDetailsFromRightnow(listBoys: any) {
    const listPromise = [];
    for (var i = 0; i <= listBoys?.length - 1; i++) {
      const currentItem = listBoys[i];
      const apiPromise = getRightnowInfoById(listBoys[i].id).then(
        async (res_rightnow: any) => {
          if (res_rightnow?.status == 200) {
            //fortmat User
            const formatToRightnowUser: UserChatType = {
              ...currentItem,
              age: res_rightnow?.data?.age,
              countryCode: res_rightnow?.data?.countryCode,
              gender: res_rightnow?.data?.gender,
              nickName: res_rightnow?.data?.nickName,
              avatar:
                (res_rightnow?.data?.typeUser == "TEST"
                  ? setDefaultAvatarForTestAccount(res_rightnow?.data?.gender)
                  : res_rightnow?.data?.pictures?.[0]) || DEFAULT_IMAGE_URL,
              isMaleSubscribe: res_rightnow?.data?.wallet?.rightnowPlusUntil,
              rawRightnowInfo: res_rightnow?.data,
            };

            return formatToRightnowUser;
          }
        }
      );

      listPromise.push(apiPromise);
    }
    const listResult = await Promise.all(listPromise);
    return listResult;
  }

  async function getBoyList(page = 1) {
    const res = await chatSDK_getListFriends(
      currentHunter?.chatSDKAccessToken,
      page
    );

    const formatToRightnowUser: UserChatType[] = res?.data?.data?.map(
      (i: any) => {
        return {
          id: i?.participant?.externalId,
          externalId: i?.participant?.id,
          nickName: i?.participant?.globalName,
          avatar: i?.participant?.avatar?.avatarURL,
          countUnread: i?.countUnread || 0,
          lastMessageContent: i?.lastMessage?.content,
          lastMessageDate: i?.lastMessage?.timestamp,
        };
      }
    );

    const renderData = await getInfoDetailsFromRightnow(formatToRightnowUser);

    if (res?.status == 200) {
      if (page == 1) {
        setListBoy(renderData);
        setCurrentPrey(renderData[0]);
      } else
        setListBoy((preState: any) => {
          return [...preState, ...renderData];
        });
      setTotalCountUnread((preState) => preState + currentHunter?.countUnread);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    if (totalCountUnread >= 0)
      updateHunterCount(currentHunter?.externalId, totalCountUnread);
  }, [totalCountUnread]);

  useEffect(() => {
    const sum = listBoys?.reduce((total: number, boy: any) => {
      return total + boy?.countUnread;
    }, 0);

    setTotalCountUnread(sum);
  }, [listBoys]);

  //case 1: first time count total
  //case 2: incomming chat
  //case 3: select -> update to zero

  //step 2: sort list after count => active + sort
  useEffect(() => {
    //active hunter must send or receive
    const isHunterSendOrReceived =
      incommingMess?.receiverId == currentHunter?.externalId ||
      incommingMess?.author?.id == currentHunter?.externalId;

    if (!isHunterSendOrReceived) return;

    //old boys receive or send chat
    const indexMatch = listBoys.findIndex(
      (i: any) =>
        i.externalId == incommingMess?.receiverId ||
        i?.externalId == incommingMess?.author?.id
    );

    let cloneArray = [...listBoys];
    let updatedPrey;

    if (indexMatch >= 0) {
      cloneArray.unshift(cloneArray.splice(indexMatch, 1)[0]);
      updatedPrey = listBoys[indexMatch];
    } else {
      //new user chat to this active hunter
      updatedPrey = {
        id: incommingMess?.author?.externalId,
        externalId: incommingMess?.author?.id,
        avatar: incommingMess?.author?.avatar?.avatarURL,
        nickName: incommingMess?.author?.globalName,
        countUnread: 0,
        lastMessageContent: "",
      };
    }

    if (updatedPrey) {
      const isActivePrey = currentPrey?.externalId == updatedPrey?.externalId;

      updatedPrey.lastMessageContent = incommingMess?.content;

      if (!isActivePrey) {
        updatedPrey.countUnread = updatedPrey.countUnread + 1;

        setTotalCountUnread((preState) => preState + 1);
      }

      if (indexMatch >= 0) cloneArray[0] = updatedPrey;
      else cloneArray = [updatedPrey, ...listBoys];
      setListBoy(cloneArray);
    }
  }, [incommingMess]);

  useEffect(() => {
    const updateCountAfterSelect = listBoys?.map((i: any) => {
      if (i?.externalId == currentPrey?.externalId) {
        if (i?.countUnread >= 0)
          setTotalCountUnread((preState) => preState - i?.countUnread);

        return {
          ...i,
          countUnread: 0,
        };
      }
      return { ...i };
    });

    setListBoy(updateCountAfterSelect);
  }, [currentPrey]);

  useEffect(() => {
    setSubcriberSelection("all");
    setFilterBoys(listBoys);
  }, [listBoys]);

  return (
    <div className="card card-flush">
      <div
        style={{ padding: "0 2.25rem" }}
        className="custom_filter_subscriber_select d-flex justify-content-end"
      >
        <Select
          value={subscriberSelection}
          style={{ minWidth: 140 }}
          onChange={async (value) => {
            switch (value) {
              case "all":
                setSubcriberSelection("all");
                setFilterBoys(listBoys);
                break;

              case "sub":
                setSubcriberSelection("sub");
                const subBoys = [...listBoys]?.filter(
                  (i: any) => i?.isMaleSubscribe
                );
                setFilterBoys(subBoys);
                break;

              case "no-sub":
                setSubcriberSelection("no-sub");
                const NoSubBoys = [...listBoys]?.filter(
                  (i: any) => !i?.isMaleSubscribe
                );
                setFilterBoys(NoSubBoys);
                break;
            }
          }}
          options={[
            { value: "all", label: "All" },
            { value: "sub", label: "Subscribed" },
            { value: "no-sub", label: "Non-Subscribed" },
          ]}
        />
      </div>
      <div className="card-body pt-5" id="kt_chat_contacts_body">
        <InfiniteScroll
          dataLength={filterBoys?.length}
          hasMore={true}
          next={() => {
            console.log("next");
            setPage(page + 1); //cheating
          }}
          loader=""
          height={"70vh"}
        >
          {isLoading && (
            <>
              <Skeleton loading={true} active avatar />
              <Skeleton loading={true} active avatar />
              <Skeleton loading={true} active avatar />
            </>
          )}
          {!isLoading &&
            filterBoys?.map((i: any) => {
              return (
                <>
                  <div
                    className="d-flex flex-stack py-4"
                    onClick={() => setCurrentPrey(i)}
                    style={
                      i?.id == currentPrey?.id
                        ? {
                            background: "#f3f3f3",
                          }
                        : {}
                    }
                  >
                    <div className="d-flex">
                      <div
                        onClick={() => setShowProfileById(i?.id)}
                        className="cursor-pointer symbol symbol-45px symbol-circle"
                      >
                        {i?.avatar ? (
                          <img alt="Pic" src={toAbsoluteUrlENV(i?.avatar)} />
                        ) : (
                          <span className="symbol-label bg-light-danger text-danger fs-6 fw-bolder">
                            {i?.nickName?.substring(0, 1)}
                          </span>
                        )}
                      </div>

                      <div className="ms-5">
                        <div className="fs-5 fw-bolder text-gray-900 text-hover-primary cursor-pointer">
                          <span onClick={() => setShowProfileById(i?.id)}>
                            {i?.rawRightnowInfo?.deletedAt ? (
                              <del>{i?.nickName}</del>
                            ) : (
                              i?.nickName
                            )}
                          </span>
                        </div>
                        <div
                          className="text-gray-400"
                          style={{
                            display: "flex",
                            gap: 5,
                            alignItems: "center",
                          }}
                        >
                          <div style={{ fontSize: 13 }}>{i?.age}</div>
                          <div style={{ fontSize: 15 }}>
                            {
                              ALLCOUNTRIES.find((c: any) => {
                                return c?.countryCodes
                                  ?.toString()
                                  ?.toLowerCase()
                                  .includes(i?.countryCode);
                              })?.flag
                            }
                          </div>
                          <img
                            src={i?.gender == "MALE" ? male_icon : female_icon}
                            style={{ width: 12, height: 12 }}
                          />

                          {i?.rawRightnowInfo?.userJob?.[0]?.value?.en}

                          {i?.gender == "MALE" && i?.isMaleSubscribe && (
                            <span className="badge bg-success text-light">
                              Subscribed
                            </span>
                          )}
                        </div>
                        {i?.lastMessageContent && (
                          <>
                            <div className="fw-bold text-gray-400 fs-6">
                              {truncateText(i?.lastMessageContent)}
                            </div>
                            <div className="text-muted fs-8">
                              {convertToHCM(i?.lastMessageDate)}
                            </div>
                          </>
                        )}
                      </div>
                    </div>

                    <div className="d-flex flex-column align-items-end ms-2">
                      <span className="text-muted fs-7 mb-1">
                        {i?.countUnread ? (
                          <span className="badge badge-sm badge-circle badge-light-danger text-white bg-danger fs-8 p-4">{`${i.countUnread}`}</span>
                        ) : null}
                      </span>
                    </div>
                  </div>
                </>
              );
            })}
        </InfiniteScroll>
      </div>
    </div>
  );
}
