import { KTIcon, toAbsoluteUrlENV } from "_metronic/helpers";
import { Select, notification } from "antd";
import { translateMessage } from "app/contexts/chatSDKContext/api";
import clsx from "clsx";
import Fancybox from "app/_components/Fancybox";
import AsyncImage from "../AsyncImage";
import { convertToHCM } from "app/utils";
import { useState } from "react";
import modal from "antd/es/modal";
import {
  chatSDK_UpdateMetaAfterBuyAlbum,
  chatSDK_UpdateMetaAfterBuyImage,
  maleBuyAlbum,
  maleBuyImage,
} from "app/contexts/chatSDKContext/male_api";

export default function ShowMessage({
  message,
  setShowProfileById,
  currentHunter,
  currentPrey,
  index,
  contentClass,
  templateAttr,
  state,
}: any) {
  return (
    <div
      key={`message${index}`}
      className={clsx("d-flex", contentClass, "mb-10", {
        "d-none": message.template,
      })}
      {...templateAttr}
    >
      <div
        className={clsx(
          "d-flex flex-column align-items",
          `align-items-${message.type === "in" ? "start" : "end"}`
        )}
      >
        <div className="d-flex align-items-center mb-2">
          {message.type === "in" ? (
            <>
              <div
                onClick={() => setShowProfileById(currentPrey?.id)}
                className="cursor-pointer symbol symbol-35px symbol-circle"
              >
                <img alt="Pic" src={toAbsoluteUrlENV(currentPrey?.avatar)} />
              </div>
              <div className="ms-3">
                <div
                  onClick={() => setShowProfileById(currentPrey?.id)}
                  className="fs-5 fw-bolder text-gray-900 text-hover-primary me-1 cursor-pointer"
                >
                  {currentPrey?.nickName}
                </div>
                <p className="text-muted fs-9 mb-1">
                  {convertToHCM(message.time)}
                </p>
              </div>
            </>
          ) : (
            <>
              <div className="me-3">
                <div
                  onClick={() => setShowProfileById(currentHunter?.id)}
                  className="fs-5 fw-bolder text-gray-900 text-hover-primary ms-1 cursor-pointer"
                >
                  You {`- ${currentHunter?.nickName}`}
                </div>
                <p className="text-muted fs-9 mb-1 text-end">
                  {convertToHCM(message.time)}
                </p>
              </div>
              <div
                onClick={() => setShowProfileById(currentHunter?.id)}
                className="cursor-pointer symbol symbol-35px symbol-circle"
              >
                <img alt="Pic" src={toAbsoluteUrlENV(currentHunter?.avatar)} />
              </div>
            </>
          )}
        </div>

        {message?.albums?.length > 0 &&
          message?.albums?.map((album: any) => {
            if (!album?.albumImages?.length) return <></>;
            return (
              <>
                <strong className="d-flex align-items-center gap-1">
                  {album?.lock ? (
                    <>
                      {" "}
                      <div
                        onClick={() =>
                          modal.confirm({
                            title: "Buy Album",
                            content:
                              "You will be charged " + album?.price + " NOW",
                            onOk: async () => {
                              const res = await maleBuyAlbum(
                                currentHunter.id,
                                currentPrey.id,
                                album.albumId
                              );

                              if (res?.status == 200) {
                                chatSDK_UpdateMetaAfterBuyAlbum(
                                  message.rawChat.attachments,
                                  currentHunter.chatSDKAccessToken
                                );

                                notification.success({
                                  message: `Buy album sucess`,
                                  duration: 3,
                                });
                              } else {
                                notification.error({
                                  message: `Buy album failed`,
                                  duration: 3,
                                });
                              }
                            },
                          })
                        }
                      >
                        <KTIcon
                          iconName="handcart"
                          className="fs-2 cursor-pointer"
                        />
                      </div>{" "}
                      {`${Number(album?.price) || 0} NOW-`}
                    </>
                  ) : (
                    <KTIcon iconName="dollar" className="fs-2" />
                  )}
                  Album {`${album?.title || "no-name"}`}
                </strong>

                <Fancybox
                  options={{
                    Carousel: {
                      infinite: true,
                    },
                  }}
                  key={Math.random()}
                >
                  <div style={{ display: "inline-block" }}>
                    <div className="d-flex gap-2 flex-wrap align-items-center">
                      {album?.albumImages?.length > 0 &&
                        Array.from(album?.albumImages)?.map((i: any) => {
                          /* return (
                            <div className="symbol-label overflow-hidden mb-2 position-relative">
                              <a
                                data-fancybox="gallery"
                                href={i?.url}
                                key={Math.random()}
                                className=""
                              >
                                <img
                                  src={i?.url}
                                  style={{
                                    maxHeight: 200,
                                    maxWidth: 200,
                                  }}
                                  className="position-relative"
                                />
                              </a>
                            </div>
                          ); */

                          return <AsyncImage i={i} />;
                        })}
                    </div>
                  </div>
                </Fancybox>
              </>
            );
          })}

        {message?.imagesInfo?.length > 0 && (
          <Fancybox
            options={{
              Carousel: {
                infinite: true,
              },
            }}
            key={Math.random()}
          >
            <div style={{ display: "inline-block" }}>
              <div className="d-flex gap-2 flex-wrap align-items-center">
                {message?.imagesInfo?.map((i: any, imageListIndex: any) => {
                  if (!i?.url) return;
                  return (
                    <>
                      {/* <div className="symbol-label overflow-hidden mb-2 position-relative">
                        <a
                          data-fancybox="gallery"
                          href={i?.url}
                          key={Math.random()}
                          className=""
                        >
                          <img
                            src={i?.url}
                            style={{
                              maxHeight: 200,
                              maxWidth: 200,
                            }}
                            className="position-relative"
                          />
                          {Number(i?.price) > 0 && (
                            <div
                              className="position-absolute top-0"
                              style={{ right: 5 }}
                            >
                              {i?.lock ? (
                                <div className="fs-6">
                                  {" "}
                                  {Number(i?.price)}
                                </div>
                              ) : (
                                <KTIcon
                                  iconName="dollar"
                                  className="fs-2"
                                />
                              )}
                            </div>
                          )}
                        </a>
                      </div> */}
                      <AsyncImage i={i} />
                      {i?.lock && (
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                            modal.confirm({
                              title: "Buy Image",
                              content:
                                "You will be charged " + i?.price + " NOW",
                              onOk: async () => {
                                const res = await maleBuyImage(
                                  currentHunter.id,
                                  i?.imageId
                                );

                                if (res?.status == 200) {
                                  const imageBuy =
                                    message.rawChat.attachments[imageListIndex];

                                  chatSDK_UpdateMetaAfterBuyImage(
                                    imageBuy,
                                    currentHunter.chatSDKAccessToken
                                  );

                                  notification.success({
                                    message: `Buy image sucess`,
                                    duration: 3,
                                  });
                                } else {
                                  notification.error({
                                    message: `Buy image failed`,
                                    duration: 3,
                                  });
                                }
                              },
                            });
                          }}
                        >
                          <KTIcon
                            iconName="handcart"
                            className="fs-2 cursor-pointer"
                          />
                        </div>
                      )}
                    </>
                  );
                })}
              </div>
            </div>
          </Fancybox>
        )}

        {message?.text && (
          <>
            <div
              className={clsx(
                "p-5 rounded",
                `bg-light-${state}`,
                "text-dark fw-bold mw-lg-400px",
                `text-${message.type === "in" ? "start" : "end"}`
              )}
              data-kt-element="message-text"
              dangerouslySetInnerHTML={{ __html: message.text }}
              id={`text-${message?.text}`}
            ></div>

            <div className="custom_languguage_select mt-2">
              <Select
                placeholder="Translate to.."
                onChange={async (value) => {
                  const res = await translateMessage(
                    message?.text || "",
                    value
                  );

                  if (res?.status == 200 || res?.status == 201) {
                    var theDiv = document.getElementById(
                      `text-${message?.text}`
                    );
                    if (theDiv)
                      theDiv.innerHTML = `<u style="font-weight:500">${message.text}</u> <p>${res?.data?.text}</p>`;
                  }
                }}
                options={[
                  { value: "en", label: "English" },
                  { value: "vi", label: "Tiếng Việt" },
                  { value: "ko", label: "한국어" },
                ]}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
}
