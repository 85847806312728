import { toAbsoluteUrlENV } from "_metronic/helpers";
import male_icon from "_metronic/assets/svgs/male-icon.png";
import female_icon from "_metronic/assets/svgs/female-icon.png";

import now_pink_icon from "_metronic/assets/svgs/now_pink.png";
import now_blue_icon from "_metronic/assets/svgs/now_blue.png";
import { useEffect, useState } from "react";
import axios from "axios";

export default function ChatBoxHeader({
  currentHunter,
  currentPrey,
  setShowProfileById,
}: any) {
  const [locationDetails, setLocationDetails] = useState<any>();

  const lat = currentPrey?.rawRightnowInfo?.latitude;
  const long = currentPrey?.rawRightnowInfo?.longitude;

  async function getLocation(lat: any, long: any) {
    const res = await axios.get(
      `https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${long}&format=json`
    );
    console.log(res);
    if (res?.status == 200) setLocationDetails(res?.data);
  }

  useEffect(() => {
    if (lat && long) getLocation(lat, long);
  }, [lat, long]);

  return (
    <div className="card-header ps-1rem" id="kt_chat_messenger_header">
      <div className="card-title">
        <div className="symbol-group symbol-hover"></div>

        {currentPrey?.nickName && (
          <div className="d-flex justify-content-center align-items-center gap-2">
            <div
              onClick={() => setShowProfileById(currentPrey?.id)}
              className="cursor-pointer symbol symbol-45px symbol-circle"
            >
              {currentPrey?.avatar ? (
                <img alt="Pic" src={toAbsoluteUrlENV(currentPrey?.avatar)} />
              ) : (
                <span className="symbol-label bg-light-danger text-danger fs-6 fw-bolder">
                  {currentPrey?.nickName?.substring(0, 1)}
                </span>
              )}
            </div>

            <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
              <div
                className="fs-4 fw-bolder text-gray-900 text-hover-primary cursor-pointer"
                onClick={() => setShowProfileById(currentPrey?.id)}
              >
                {currentPrey?.rawRightnowInfo?.deletedAt ? (
                  <del>{currentPrey?.nickName}</del>
                ) : (
                  currentPrey?.nickName
                )}
              </div>
              <div>
                <img
                  src={
                    currentHunter?.gender == "FEMALE" ? male_icon : female_icon
                  }
                  style={{
                    width: 14,
                    height: 14,
                    marginTop: "-5px",
                  }}
                />
              </div>

              <div style={{ fontSize: 12, gap: 10 }}>
                {currentPrey?.rawRightnowInfo?.userJob?.[0]?.value?.en}
              </div>

              {currentPrey?.gender == "MALE" &&
                currentPrey?.isMaleSubscribe && (
                  <span className="badge bg-success text-light">
                    Subscribed
                  </span>
                )}
            </div>

            <div style={{ fontSize: 12, display: "flex", gap: 10 }}>
              <div>
                {currentPrey?.rawRightnowInfo?.wallet?.balance || 0}{" "}
                <img
                  src={
                    currentPrey?.gender == "FEMALE"
                      ? now_pink_icon
                      : now_blue_icon
                  }
                  style={{
                    width: 14,
                    height: 14,
                    marginTop: "-5px",
                  }}
                />
              </div>
              <div>
                {lat && long ? (
                  <a
                    // href={`https://www.google.com/maps/@${lat},${long},15.18z?entry=ttu`}
                    href={`https://www.google.com/maps/search/${lat},${long}/@${lat},${long},17z?entry=ttu`}
                    target="_blank"
                  >
                    {locationDetails?.address?.city ||
                    locationDetails?.address?.state
                      ? `${
                          locationDetails?.address?.city ||
                          locationDetails?.address?.state
                        }-${locationDetails?.address?.country}`
                      : "Location"}
                  </a>
                ) : (
                  "No location"
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
