/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useContext, useEffect, useRef, useState } from "react";
import clsx from "clsx";
import {
  toAbsoluteUrlENV,
  defaultMessages,
  MessageModel,
  messageFromClient,
  KTIcon,
} from "_metronic/helpers";
import {
  chatSDK_getPrivateChatId,
  getListMessages,
  saveImageChatToRightnowAlbum,
  translateMessage,
  uploadMulipleImages,
} from "app/contexts/chatSDKContext/api";
import { convertToHCM } from "app/utils";
import { useChat } from "app/contexts/chat";
import { ChatSDKContext } from "app/contexts/chatSDKContext";
import { realtimeChat } from "app/contexts/chatSDKContext/socketEmit";
import InfiniteScroll from "react-infinite-scroll-component";
import Fancybox from "app/_components/Fancybox";
import { Modal, Select, Upload, notification } from "antd";
import {
  chatSDK_UpdateMetaAfterBuyAlbum,
  chatSDK_UpdateMetaAfterBuyImage,
  maleBuyAlbum,
  maleBuyImage,
  nowTransactionChatbox,
} from "app/contexts/chatSDKContext/male_api";
import AsyncImage from "../AsyncImage";
import MaleChatFooter from "./MaleChatFooter";
import MaleMessage from "./MaleMessage";

const MaleChatBox = ({
  isDrawer = false,
  currentHunter,
  currentPrey,
  setShowProfileById,
}: any) => {
  const { message: incommingMess } = useChat();
  const [modal, contextHolder] = Modal.useModal();
  const [privateChatId, setPrivateChatId] = useState("");
  const [chatUpdateFlag, toggleChatUpdateFlat] = useState<boolean>(false);

  const [message, setMessage] = useState<string>("");
  const [messages, setMessages] = useState<any[]>([]);
  const messagesEndRef = useRef<null | HTMLDivElement>(null);
  const [tempImgs, setTempImgs] = useState<any>([]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (
      incommingMess?.boxId == privateChatId &&
      incommingMess?.receiverId != currentPrey?.externalId
    ) {
      const newMessage: MessageModel = {
        user: 2,
        type: "in",
        text: incommingMess?.content,
        time: incommingMess?.createdTimestamp,
        imagesInfo: incommingMess?.attachments?.map((i: any) => {
          const metaData = JSON.parse(i?.meta);
          return {
            url: i?.path,
            price: Number(metaData?.price) || 0,
            lock: metaData?.lock || true,
          };
        }),

        albums: incommingMess?.attachments?.map((i: any) => {
          try {
            const metaData = JSON.parse(i?.meta);

            const albumImages = JSON.parse(metaData?.imageList);

            return {
              albumId: metaData?.albumId,
              title: metaData?.originalName,
              price: Number(metaData?.price) || 0,
              lock: metaData?.lock,
              albumImages,
            };
          } catch (error) {
            return { url: i?.path };
          }
        }),
      };

      const bufferMessages = messages;
      bufferMessages.push(newMessage);

      setMessages(bufferMessages);
      toggleChatUpdateFlat(!chatUpdateFlag);
    }
  }, [incommingMess]);

  //custom

  useEffect(() => {
    if (currentPrey?.externalId && currentHunter?.chatSDKAccessToken) {
      getPrivateChatId();
      getChat(true);
    }
  }, [currentHunter, currentPrey]);

  async function getPrivateChatId() {
    const res = await chatSDK_getPrivateChatId(
      currentPrey?.externalId,
      currentHunter?.chatSDKAccessToken
    );

    if (res?.status == 200) setPrivateChatId(res?.data?.id);
  }

  async function getChat(isReplace = true, lastMessageId = "null") {
    const res = await getListMessages(
      currentPrey?.externalId,
      currentHunter?.chatSDKAccessToken,
      lastMessageId
    );
    if (res?.status == 200) {
      const chat = res?.data?.data?.items?.map((c: any) => {
        return {
          rawChat: c,
          chatId: c?.id,
          type: c?.author?.id == currentPrey?.externalId ? "in" : "out",
          text: c?.content,
          time: c?.createdTimestamp,
          imagesInfo: c?.attachments?.map((i: any) => {
            try {
              const metaData = JSON.parse(i?.meta);
              return {
                imageId: metaData?.imageId,
                url: i?.path,
                price: Number(metaData?.price) || 0,
                lock: metaData?.lock,
              };
            } catch (error) {
              return { url: i?.path };
            }
          }),

          albums: c?.attachments?.map((i: any) => {
            try {
              const metaData = JSON.parse(i?.meta);

              const albumImages = JSON.parse(metaData?.imageList);

              console.log(metaData, albumImages);

              return {
                albumId: metaData?.albumId,
                title: metaData?.originalName,
                price: Number(metaData?.price) || 0,
                lock: metaData?.lock,
                albumImages,
              };
            } catch (error) {
              return { url: i?.path };
            }
          }),
        };
      });

      if (isReplace) setMessages(chat);
      else
        setMessages((preState) => {
          const listChatId = preState?.map((i: any) => i?.chatId);
          if (listChatId.includes(chat[0]?.chatId)) return preState;
          return [...chat, ...preState];
        });
    }
  }

  useEffect(() => {
    //update list message
  }, [privateChatId]);

  useEffect(() => {
    scrollToBottom();
  }, [incommingMess]);

  const showMess = [...messages].reverse();

  console.log(showMess);

  return (
    <div
      className="card-body"
      id={isDrawer ? "kt_drawer_chat_messenger_body" : "kt_chat_messenger_body"}
    >
      <div
        id="scrollableDiv"
        style={{
          height: "60vh",
          overflow: "auto",
          display: "flex",
          flexDirection: "column-reverse",
        }}
      >
        <InfiniteScroll
          dataLength={messages.length}
          next={() => {
            console.log("next");
            getChat(false, messages[0]?.chatId);
          }}
          inverse={true}
          hasMore={true}
          loader={<></>}
          scrollableTarget="scrollableDiv"
          style={{ display: "flex", flexDirection: "column-reverse" }}
        >
          <>
            <div ref={messagesEndRef}></div>

            {showMess.map((message, index) => {
              const state = message.type === "in" ? "info" : "primary";
              const templateAttr = {};
              if (message.template) {
                Object.defineProperty(templateAttr, "data-kt-element", {
                  value: `template-${message.type}`,
                });
              }
              const contentClass = `${
                isDrawer ? "" : "d-flex"
              } justify-content-${
                message.type === "in" ? "start" : "end"
              } mb-10`;

              return (
                <MaleMessage
                  message={message}
                  setShowProfileById={setShowProfileById}
                  currentHunter={currentHunter}
                  currentPrey={currentPrey}
                  index={index}
                  contentClass={contentClass}
                  templateAttr={templateAttr}
                  state={state}
                />
              );
            })}
          </>
        </InfiniteScroll>
      </div>

      {!currentPrey?.rawRightnowInfo?.deletedAt && (
        <MaleChatFooter
          currentHunter={currentHunter}
          currentPrey={currentPrey}
          messages={messages}
          setMessages={setMessages}
          privateChatId={privateChatId}
        />
      )}

      <div>{contextHolder}</div>
    </div>
  );
};

export { MaleChatBox };
