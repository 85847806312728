import { FC, Suspense, lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import TopBarProgress from "react-topbar-progress-indicator";
import { getCSSVariableValue } from "../../_metronic/assets/ts/_utils";
import { WithChildren } from "../../_metronic/helpers";
import { MasterLayout } from "../../_metronic/layout/MasterLayout";
import ChatPage from "../_modules/chat";
import { PageTitle } from "_metronic/layout/core";
import clsx from "clsx";
import { useIntl } from "react-intl";
import ProfilePage from "app/_modules/users-profile/ProfilePage";

const PrivateRoutes = () => {
  const intl = useIntl();

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path="auth/*" element={<Navigate to="/" />} />

        <Route path="profile/:id/*" element={<ProfilePage />} />

        <Route
          path="chat/*"
          element={
            <>
              <PageTitle>
                <div
                  id="kt_page_title"
                  data-kt-swapper="true"
                  data-kt-swapper-mode="prepend"
                  data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      flexWrap: "wrap",
                      gap: 5,
                      position: "relative",
                    }}
                  >
                    <div className={clsx("menu-title fw-bold fs-5")}>
                      {intl.formatMessage({ id: "OPERATOR-CHAT-MANAGMENT" })}
                    </div>
                  </div>
                  <div style={{ fontSize: 9 }}>
                    Please enable permission in both brower and device to
                    receive notification
                  </div>
                </div>
              </PageTitle>
              <ChatPage />
            </>
          }
        />

        {/* Page Not Found */}
        <Route path="*" element={<Navigate to="/chat" />} />
      </Route>
    </Routes>
  );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue("--bs-primary");
  TopBarProgress.config({
    barColors: {
      "0": baseColor,
      "1.0": baseColor,
    },
    barThickness: 3,
    shadowBlur: 3,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };
