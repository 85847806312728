import React, { useEffect, useState } from "react";
import { Button, Modal } from "antd";
import { useIntl } from "react-intl";
import { getUserById } from "../users-profile/core/_requests";
import { ProfileHeader } from "../users-profile/ProfileHeader";

const UserProfileModal = ({ userId, onClose }: any) => {
  const intl = useIntl();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalText, setModalText] = useState("Content of the modal");

  const [users, setUsers] = useState<any>();

  useEffect(() => {
    async function GetData() {
      const res = await getUserById(userId);
      setUsers(res);
    }

    GetData();
  }, []);

  return (
    <>
      <Modal
        title={intl.formatMessage({
          id: "DETAIL",
        })}
        open={true}
        confirmLoading={confirmLoading}
        onCancel={() => onClose()}
        width={"50%"}
        footer={null}
      >
        <ProfileHeader user={users} />
      </Modal>
    </>
  );
};

export default UserProfileModal;
