import axios from "axios";

const BASE_URL_API = process.env.REACT_APP_BASE_API_URL;
const CHATSDK_UPLOAD_DOMAIN = process.env.REACT_APP_CHAT_SDK_UPLOAD_DOMAIN;

export async function maleBuyAlbum(
  currentHunterRightnowId: string,
  currentPreyRightnowId: string,
  albumId: string
) {
  const body = {
    sellerId: Number(currentPreyRightnowId), // id của người bán
    // tạm bỏ qua logic sửa album đã bán
  };

  return axios.put(
    `${BASE_URL_API}/operator-album/${albumId}/buy/${currentHunterRightnowId}`,
    body
  );
}

export async function chatSDK_UpdateMetaAfterBuyAlbum(
  attachments: any[],
  chatSDKAccessToken: string
) {
  console.log(attachments);
  const body = {
    files: attachments?.map((attachment: any) => {
      return {
        ...attachment,
        meta: attachment?.meta?.replace(`lock\":true`, `lock\":false`),
      };
    }),
  };
  if (!chatSDKAccessToken) return;

  const config = {
    headers: { Authorization: `Bearer ${chatSDKAccessToken}` },
  };

  return await axios.patch(`${CHATSDK_UPLOAD_DOMAIN}/file/meta`, body, config);
}

export async function maleBuyImage(
  currentHunterRightnowId: string,
  imageId: string
) {
  return axios.put(
    `${BASE_URL_API}/operator-album/${imageId}/file/${currentHunterRightnowId}`
  );
}

export async function chatSDK_UpdateMetaAfterBuyImage(
  attachment: any,
  chatSDKAccessToken: string
) {
  console.log(attachment);
  const body = {
    files: [
      {
        ...attachment,
        meta: attachment?.meta?.replace(`lock\":true`, `lock\":false`),
      },
    ],
  };
  if (!chatSDKAccessToken) return;

  const config = {
    headers: { Authorization: `Bearer ${chatSDKAccessToken}` },
  };

  return await axios.patch(`${CHATSDK_UPLOAD_DOMAIN}/file/meta`, body, config);
}

export async function nowTransactionChatbox(
  sendfrom_UserId: any,
  sendTo_UserId: any
) {
  const body = {
    toUserId: sendTo_UserId,
    payType: "chat_user", // view_profile | chat_profile | chat_user | special_place | view_message
  };

  return await axios.post(
    `${BASE_URL_API}/operator-user/pay/${sendfrom_UserId}`,
    body
  );
}
