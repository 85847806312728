import { KTIcon, toAbsoluteUrl } from "_metronic/helpers";
import { getImageFromWorker } from "app/contexts/chatSDKContext/api";
import { useEffect, useState } from "react";
import Gallery from "react-photo-gallery";

export default function AlbumGridAsyncImage({ urlArr }: any) {
  const [arrRenderBlob, setArrRenderBlob] = useState<any>([]);
  useEffect(() => {
    async function getData() {
      if (arrRenderBlob?.length && !urlArr?.length) return;
      const formatURL0 = await fortmatImage(urlArr[0]);
      const formatURL1 = await fortmatImage(urlArr[1]);
      const formatURL2 = await fortmatImage(urlArr[2]);
      const formatURL3 = await fortmatImage(urlArr[3]);

      setArrRenderBlob([formatURL0, formatURL1, formatURL2, formatURL3]);
    }

    getData();
  }, [urlArr]);

  async function fortmatImage(rawURL: string) {
    if (!rawURL) return toAbsoluteUrl("/media/avatars/notfound_lightGray.jpeg");
    const resImgWorker = await getImageFromWorker(rawURL);
    //console.log(resImgWorker);

    if (resImgWorker?.status == 200) {
      const blobImg = URL.createObjectURL(resImgWorker?.data);
      return blobImg;
    }

    return toAbsoluteUrl("/media/avatars/notfound_lightGray.jpeg");
  }

  return (
    <div className="d-flex" style={{ flexWrap: "wrap", gap: 5 }}>
      {arrRenderBlob?.map((i: any) => {
        return <img src={i} width={45} height={45} />;
      })}
    </div>
  );
}
