export enum EVENT_NAME {
  DISCONNECT = "disconnect",
  SEND_MESSAGE = "sendMessage",
  UPDATE_CHAT_DIRECT_MESSAGE = "updateChatDirectMessage",
  UPDATE_CHAT_GROUP = "updateChatGroup",
  UPDATE_NAME_GROUP = "updateNameGroup",
  JOIN_GROUP = "joinGroup",
  LEAVE_GROUP = "leaveGroup",
  LEAVE_PRIVATE_CHAT = "leavePrivateChat",
  JOIN_PRIVATE_CHAT = "joinPrivateChat",
  INVITED_TO_GROUP = "invitedToGroup",
  INVITE_TO_GROUP = "inviteToGroup",

  CHANGE_ALIAS_NAME = "changeAliasName",
  CHANGE_GROUP_NAME = "changeGroupName",

  CREATE_USER = "createUser",
  UPDATE_ROOMS = "updateRooms",
  CREATE_ROOM = "createRoom",

  EXCEPTION = "exception",
  ERROR = "error",

  MESSAGE = "message",

  // SDK
  UPDATE_NOTIFICATION = "updateNotification",

  GROUP_REMOVED = "groupRemoved",

  REMOVED_FROM_GROUP_BY_OWNER = "removedFromGroupByOwner",

  UPDATE_METADATA_DIRECT_MESSAGE = "updateMetadataDirectMessage",
}
