import XLSX from "xlsx";
import dayjs from "dayjs";
import type { Dayjs } from "dayjs";
import type { RangePickerProps } from "antd/es/date-picker";
import { RcFile } from "antd/es/upload";
import moment from "moment";
import "moment-timezone";

export const downloadExcel = async (data: any, filename: any) => {
  const rows = data || [];
  // create workbook and worksheet
  const workbook = XLSX?.utils?.book_new();
  const worksheet = XLSX?.utils?.json_to_sheet(rows);
  XLSX?.utils?.book_append_sheet(workbook, worksheet);
  // customize header names
  // XLSX?.utils?.sheet_add_aoa(worksheet, [
  //   ["Product ID", "Product Name", "Product Category"],
  // ]);
  XLSX?.writeFile(workbook, `${filename}?.xlsx`, { compression: true });
};

export function linkPagination(
  current: number,
  totalRows: number,
  perPage: number
) {
  const maxPage = Math?.ceil(totalRows / perPage);
  const array = [
    current - 3,
    current - 2,
    current - 1,
    current,
    current + 1,
    current + 2,
    current + 3,
  ];

  const showArr = array
    ?.map((i, index) => {
      if (i <= 0 || i > maxPage) return;

      if (index == 0 && i >= 1)
        return {
          label: "PREVIOUS",
          page: current - 1,
        };
      if (index == array?.length - 1 && i <= maxPage)
        return {
          label: "NEXT",
          page: current + 1,
        };
      return {
        label: i,
        page: i,
      };
    })
    ?.filter(function (element) {
      return (
        element !== undefined &&
        !Number?.isNaN(element?.label) &&
        element?.label !== undefined
      );
    });

  if (showArr?.length > 0) return showArr;
}

export function stringifyDateTime(date: string) {
  if (date) return `${date} 00:00:00`;
  else return null;
}

export const rangePresets: {
  label: string;
  value: [Dayjs, Dayjs];
}[] = [
  { label: "최근 7일", value: [dayjs()?.add(-7, "d"), dayjs()] },
  { label: "최근 14일", value: [dayjs()?.add(-14, "d"), dayjs()] },
  { label: "최근 30일", value: [dayjs()?.add(-30, "d"), dayjs()] },
  // { label: 'Last 90 Days', value: [dayjs()?.add(-90, 'd'), dayjs()] },
];

export const rangePresetsEn: {
  label: string;
  value: [Dayjs, Dayjs];
}[] = [
  { label: "Last 7 days", value: [dayjs()?.add(-7, "d"), dayjs()] },
  { label: "Last 14 days", value: [dayjs()?.add(-14, "d"), dayjs()] },
  { label: "Last 30 days", value: [dayjs()?.add(-30, "d"), dayjs()] },
  // { label: 'Last 90 Days', value: [dayjs()?.add(-90, 'd'), dayjs()] },
];

export const disabledDateAfterToday: RangePickerProps["disabledDate"] = (
  current
) => {
  // Can not select days before today and today
  return current > dayjs()?.endOf("day");
};

export const disabledDateBeforeAndToday: RangePickerProps["disabledDate"] = (
  current
) => {
  // Can not select days before today and today
  return current && current < dayjs()?.endOf("day");
};

export function objToString(obj: any) {
  var str = "";
  for (var p in obj) {
    if (Object?.prototype?.hasOwnProperty?.call(obj, p)) {
      str += p + "::" + obj[p] + "\n";
    }
  }
  return str;
}

export function isEmptyObject(empty: any) {
  return (
    empty !== undefined &&
    empty !== null &&
    Object?.keys(empty)?.length === 0 &&
    empty?.constructor === Object
  );
}

export function FormatWorkingTime(stringObj: any) {
  // console.log(stringObj);
  // const obj = JSON.parse(stringObj.toString());
  // console?.log(obj);
  const obj = stringObj;

  const result = (
    <>
      <p>
        {obj?.monday?.from && `Monday: ${obj?.monday?.from}-${obj?.monday?.to}`}
      </p>
      <p>
        {obj?.tuesday?.from &&
          `Tuesday: ${obj?.tuesday?.from}-${obj?.tuesday?.to}`}
      </p>
      <p>
        {obj?.wednesday?.from &&
          `Wednesday: ${obj?.wednesday?.from}-${obj?.wednesday?.to}`}
      </p>
      <p>
        {obj?.thursday?.from &&
          `Thursday: ${obj?.thursday?.from}-${obj?.thursday?.to}`}
      </p>
      <p>
        {obj?.friday?.from && `Friday: ${obj?.friday?.from}-${obj?.friday?.to}`}
      </p>
      <p>
        {obj?.saturday?.from &&
          `Saturday: ${obj?.saturday?.from}-${obj?.saturday?.to}`}
      </p>
      <p>
        {obj?.sunday?.from && `Sunday: ${obj?.sunday?.from}-${obj?.sunday?.to}`}
      </p>
    </>
  );

  return result;
}

export const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

export function randomAntdColor() {
  const textArray = [
    "processing",
    "success",
    "error",
    "warning",
    "magenta",
    "red",
    "volcano",
    "orange",
    "gold",
    "lime",
    "green",
    "cyan",
    "blue",
    "geekblue",
    "purple",
  ];
  var randomNumber = Math.floor(Math.random() * textArray.length);
  return textArray[randomNumber];
}

export function applyDefaultQueryConditions(query: any) {
  const newQuery = { ...query };

  if (!newQuery.limit || !newQuery.page) {
    newQuery.limit = 10;
    newQuery.page = 1;
  }

  return newQuery;
}

export const isPermitted = (permission: string, allPermissions: string[]) => {
  return allPermissions.includes(permission);
};

export const validatePhone = (phone: any) => {
  // let patterMobile = "((\\+84[1-9][0-9]{8})|(0[1-9][0-9]{8}))";
  let patterMobile = /[^0-9]/g;
  let regex = RegExp(patterMobile);
  if (regex.test(phone)) {
    return false;
  } else {
    return true;
  }
};

export function removeFirstZero(num: any) {
  if (!num) return;
  num = num?.toString();
  const result = num.replace(/^0+/, "");
  return result;
}

export function convertToHCM(timestamp: any) {
  // Convert timestamp to milliseconds
  const milliseconds = timestamp;

  // Convert to HCM timezone
  const hcmTime = moment.tz(milliseconds, "Asia/Ho_Chi_Minh");

  // Format as YYYY.MM.DD hh:mm
  const formattedTime = hcmTime.format("YYYY.MM.DD HH:mm");

  return formattedTime;
}

export function truncateText(str: string) {
  let maxLength = 50;
  let truncatedStr =
    str.length > maxLength ? str.substring(0, maxLength) + "..." : str;

  return truncatedStr;
}
