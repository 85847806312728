import { EVENT_NAME } from "app/constants/event";

export default function registerSocketEvents(socket: any, saveMessage: any) {
  if (socket) {
    // socket.on("connect", () => {
    //   console.log("kết nối thành công");
    // });
    // socket.on("reconnect", () => {
    //   console.log("re-connect thành công");
    // });
    // socket.on("disconnect", () => {
    //   console.log("socket ngắt kết nối");
    // });

    // Retry to connect socket
    // if (!socket?.connected /* && auth && auth.access_token */) {
    //   // @ts-ignore
    //   // // Reconnect with the updated headers
    //   socket.connect();
    // }

    if (!socket.hasListeners(EVENT_NAME.MESSAGE)) {
      // Listen to the event of receiving new messages to direct
      socket.on(EVENT_NAME.MESSAGE, (message: any) => {
        saveMessage(message);
      });
    }

    if (!socket.hasListeners(EVENT_NAME.UPDATE_CHAT_DIRECT_MESSAGE)) {
      // Listen to the event of receiving new messages to direct

      socket.on(EVENT_NAME.UPDATE_CHAT_DIRECT_MESSAGE, (message: any) => {
        console.log("message private", message);
        saveMessage(message);
      });
    }

    if (!socket.hasListeners(EVENT_NAME.EXCEPTION)) {
      // Listen to the event of error exception
      socket.on(EVENT_NAME.EXCEPTION, (message: any) => {
        console.log("message error in chat", message);
        //saveMessageError({ ...message, id: renderUId() });
      });
    }

    if (!socket.hasListeners(EVENT_NAME.ERROR)) {
      // Handle error events if any
      socket.on(EVENT_NAME.ERROR, (error: any) => {
        console.error("Socket error:", error);
      });
    }

    if (!socket.hasListeners(EVENT_NAME.UPDATE_METADATA_DIRECT_MESSAGE)) {
      //update status message after buy image
      // Handle error events if any
      socket.on(EVENT_NAME.UPDATE_METADATA_DIRECT_MESSAGE, (message: any) => {
        console.error("UPDATE_METADATA_DIRECT_MESSAGE: ", message);
      });
    }
  }
}
