import { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { HeaderWrapper } from "./components/header";
import { ScrollTop } from "./components/scroll-top";
import { Content } from "./components/content";
import { Sidebar } from "./components/sidebar";
import {
  DrawerMessenger,
  ActivityDrawer,
  InviteUsers,
  UpgradePlan,
  ThemeModeProvider,
} from "../partials";
import { PageDataProvider } from "./core";
import { reInitMenu } from "../helpers";
import { ToolbarWrapper } from "./components/toolbar";

import { ConfigProvider, theme } from "antd";
import { useThemeMode } from "_metronic/partials";
import { ThemeModeComponent } from "_metronic/assets/ts/layout";
const { darkAlgorithm, defaultAlgorithm } = theme;

const systemMode = ThemeModeComponent.getSystemMode() as "light" | "dark";

const LayoutContent = () => {
  const { mode, menuMode, updateMode, updateMenuMode } = useThemeMode();
  const calculatedMode = mode === "system" ? systemMode : mode;

  return (
    <ConfigProvider
      theme={{
        algorithm: calculatedMode == "dark" ? darkAlgorithm : defaultAlgorithm,
      }}
    >
      <div className="d-flex flex-column flex-root app-root" id="kt_app_root">
        <div
          className="app-page flex-column flex-column-fluid"
          id="kt_app_page"
        >
          <HeaderWrapper />
          <div
            className="app-wrapper flex-column flex-row-fluid"
            id="kt_app_wrapper"
          >
            {/* <Sidebar /> */}
            <div
              className="app-main flex-column flex-row-fluid"
              id="kt_app_main"
            >
              <div className="d-flex flex-column flex-column-fluid">
                <ToolbarWrapper />
                <Content>
                  <Outlet />
                </Content>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* begin:: Drawers */}
      <ActivityDrawer />
      <DrawerMessenger />
      {/* end:: Drawers */}

      {/* begin:: Modals */}
      <InviteUsers />
      <UpgradePlan />
      {/* end:: Modals */}
      <ScrollTop />
    </ConfigProvider>
  );
};

export { LayoutContent };
